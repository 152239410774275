import React, { useEffect, useState } from 'react';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import {
  Page,
  Card,
  Table,
  Text,
  Button
} from "tabler-react";
import RecoveryTypeStore from '../../interfaces/RecoveryTypeStore';
import { useHistory } from 'react-router-dom';

function RecoveriesList() {

  const history = useHistory();

  const [recoveryTypeStores, setRecoveryTypeStores] = useState([]);

  useEffect(() => {
    api.get('recoverytypestores').then(response => {
      setRecoveryTypeStores(response.data);
    });
  }, []);

  function handleClickAdd() {
    history.push('/recoverytypes/form');
  }

  function handleEditClick(recoveryTypeStore: RecoveryTypeStore) {
    history.push(`/recoverytypes/form/${recoveryTypeStore.id}`);
  }

  return (
    <SiteWrapper>
      <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Tipos de Resgate</Card.Title>
            <Card.Options>
            <Button
                RootComponent="a"
                color="secondary"
                size="sm"
                icon="plus"
                onClick={handleClickAdd}
              >
                Incluir
              </Button>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Tipo" },
              { content: "Descrição" },
              { content: "Pontos Necessários" },
              { content: "Limite de Uso" },
              { content: "Ativo" },
              { content: null },
            ]}
            bodyItems={
              recoveryTypeStores.map((recoveryTypeStore: RecoveryTypeStore) => {
                return {
                  key: recoveryTypeStore.id,
                  item: [
                    {
                      content: (
                        <Text>
                          { recoveryTypeStore.recoveryType.name }
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          { recoveryTypeStore.description }
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          { recoveryTypeStore.points_needed }
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          { recoveryTypeStore.usage_limit }
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          { recoveryTypeStore.active ? 'Sim' : 'Não' }
                        </Text>
                      ),
                    },
                    {
                      alignContent: "right",
                      content: (
                        <>
                           <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(recoveryTypeStore)}
                          />
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
        </Card>
      </Page.Content>
    </SiteWrapper>
  );
}

export default RecoveriesList;