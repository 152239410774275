import React from 'react';
import Route from './Route';
import { Switch } from 'react-router-dom';

import HomePage from "../pages/HomePage";
import Login from "../pages/login/Login";
import RegisterPage from "../pages/Register";
import Error404 from "../pages/exceptions/404";
import CustomersList from "../pages/customers/CustomersList";
import CustomersForm from "../pages/customers/CustomersForm";
import ImportVipsForm from "../pages/customers/ImportVipsForm";
import TransactionsList from "../pages/transactions/TransactionsList";
import TransactionsForm from "../pages/transactions/TransactionsForm";
import CashbackTransactionsList from "../pages/cashbackTransactions/CashbackTransactionsList";
import CashbackTransactionsForm from "../pages/cashbackTransactions/CashbackTransactionsForm";
import TransactionTypesList from "../pages/transactionTypes/TransactionTypesList";
import TransactionTypesForm from "../pages/transactionTypes/TransactionTypesForm";
import ProductCategoriesList from "../pages/productCategories/ProductCategoriesList";
import ProductCategoriesForm from "../pages/productCategories/ProductCategoriesForm";
import ProductsList from "../pages/products/ProductsList";
import ProductsForm from "../pages/products/ProductsForm";
import RecoveriesList from "../pages/recoveries/RecoveriesList";
import RecoveriesForm from "../pages/recoveries/RecoveriesForm";
import RegenerateCouponForm from "../pages/regenerateCoupon/RegenerateCouponForm";
import CashbackRecoveriesList from "../pages/cashbackRecoveries/CashbackRecoveriesList";
import CashbackRecoveriesForm from "../pages/cashbackRecoveries/CashbackRecoveriesForm";
import RecoveryTypesList from "../pages/recoveryTypes/RecoveryTypesList";
import RecoveryTypesForm from "../pages/recoveryTypes/RecoveryTypesForm";
import SettingsForm from "../pages/settings/SettingsForm";
import LevelsList from "../pages/levels/LevelsList";
import LevelsForm from "../pages/levels/LevelsForm";
import ReportsList from "../pages/reports/ReportsList";
import PageGeneratorForm from "../pages/pageGenerator/PageGeneratorForm";
import PageGenerate from "../pages/pageGenerator/PageGenerate";
import NotificationsList from "../pages/notifications/NotificationsList";
import NotificationsForm from "../pages/notifications/NotificationsForm";
import SubscriptionForm from "../pages/subscription/SubscriptionForm";
import ConfigureAuthentication from "../pages/configureAuthentication/ConfigureAuthentication";
import FinishRegistration from "../pages/FinishRegistration";
import LoginMonitor from '../pages/loginMonitor/LoginMonitor';
import ForgotPassword from '../pages/forgotPassword/ForgotPassword';
import Unsubscribe from '../pages/Unsubscribe/Unsubscribe';

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/login-monitor" component={LoginMonitor} />
    <Route exact path="/register" component={RegisterPage} />
    <Route exact path="/finish-registration" component={FinishRegistration} />
    <Route exact path="/forgot-password" component={ForgotPassword} />
    <Route exact path="/unsubscribe" component={Unsubscribe} />

    <Route exact path="/dashboard" component={HomePage} isPrivate />

    <Route exact path="/customers" component={CustomersList} isPrivate />
    <Route exact path="/customers/form" component={CustomersForm} isPrivate />
    <Route exact path="/customers/form/:id" component={CustomersForm} isPrivate />
    <Route exact path="/customers/importVips" component={ImportVipsForm} isPrivate />

    <Route exact path="/transactions" component={TransactionsList} isPrivate />
    <Route exact path="/transactions/form" component={TransactionsForm} isPrivate />
    <Route exact path="/transactions/form/:id" component={TransactionsForm} isPrivate />

    <Route exact path="/cashbacktransactions" component={CashbackTransactionsList} isPrivate />
    <Route exact path="/cashbacktransactions/form" component={CashbackTransactionsForm} isPrivate />
    <Route exact path="/cashbacktransactions/form/:id" component={CashbackTransactionsForm} isPrivate />

    <Route exact path="/transactiontypes" component={TransactionTypesList} isPrivate />
    <Route exact path="/transactiontypes/form" component={TransactionTypesForm} isPrivate />
    <Route exact path="/transactiontypes/form/:id" component={TransactionTypesForm} isPrivate />

    <Route exact path="/products" component={ProductsList} isPrivate />
    <Route exact path="/products/form" component={ProductsForm} isPrivate />
    <Route exact path="/products/form/:id" component={ProductsForm} isPrivate />

    <Route exact path="/productcategories" component={ProductCategoriesList} isPrivate />
    <Route exact path="/productcategories/form" component={ProductCategoriesForm} isPrivate />
    <Route exact path="/productcategories/form/:id" component={ProductCategoriesForm} isPrivate />

    <Route exact path="/recoveries" component={RecoveriesList} isPrivate />
    <Route exact path="/regeneratecoupon/form/:id" component={RegenerateCouponForm} isPrivate />
    <Route exact path="/recoveries/form" component={RecoveriesForm} isPrivate />
    <Route exact path="/recoveries/form/:id" component={RecoveriesForm} isPrivate />

    <Route exact path="/cashbackrecoveries" component={CashbackRecoveriesList} isPrivate />
    <Route exact path="/cashbackrecoveries/form" component={CashbackRecoveriesForm} isPrivate />
    <Route exact path="/cashbackrecoveries/form/:id" component={CashbackRecoveriesForm} isPrivate />

    <Route exact path="/recoverytypes" component={RecoveryTypesList} isPrivate />
    <Route exact path="/recoverytypes/form" component={RecoveryTypesForm} isPrivate />
    <Route exact path="/recoverytypes/form/:id" component={RecoveryTypesForm} isPrivate />

    <Route exact path="/settings" component={SettingsForm} isPrivate />

    <Route exact path="/levels" component={LevelsList} isPrivate />
    <Route exact path="/levels/form" component={LevelsForm} isPrivate />
    <Route exact path="/levels/form/:id" component={LevelsForm} isPrivate />

    <Route exact path="/pagegenerator" component={PageGeneratorForm} isPrivate />
    <Route exact path="/pagegenerate" component={PageGenerate} isPrivate />

    <Route exact path="/notificationtypes" component={NotificationsList} isPrivate />
    <Route exact path="/notificationtypes/form" component={NotificationsForm} isPrivate />
    <Route exact path="/notificationtypes/form/:id" component={NotificationsForm} isPrivate />

    <Route exact path="/reports" component={ReportsList} isPrivate />

    <Route exact path="/subscription" component={SubscriptionForm} isPrivate />
    <Route exact path="/configureauthentication" component={ConfigureAuthentication} isPrivate />

    <Route component={Error404} />
  </Switch>
);

export default Routes;