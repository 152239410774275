import React, { useState } from "react";
import { generateCSV } from "../../utils/downloadReport";

import SiteWrapper from "../../base/SiteWrapper";

import { Page, Card, Text, Button, Form } from "tabler-react";
import api from "../../services/api";
import { formatDate, formatDateToDDMMYYYY } from "../../utils/formatDate";
import Recovery, {
  csvHeaderFileAvailable,
  csvHeaderFileRansomed,
  monthsInPortuguese,
} from "../../interfaces/Reports";
import { applyCurrencyMask } from "../../utils/formatFloat";
import { useAuth } from "../../hooks/AuthContext";
import AvailableCashbackTransaction from "../../interfaces/CashbackTransaction";

function ReportsList() {
  const { store } = useAuth();
  const initialValueDate = new Date();
  const todayDate = new Date();

  const [selectedInitialDate, setSelectedInitialDate] = useState(initialValueDate);
  const [selectedFinalDate, setSelectedFinalDate] = useState(initialValueDate);
  const [validDate, setValidDate] = useState(false)

  async function updateRecoveriesList(page = 1): Promise<Recovery[]> {
    const data: Recovery[] = [];
    const params = {
      initialdate: formatDate(selectedInitialDate.toDateString()),
      finaldate: formatDate(selectedFinalDate.toDateString()),
      pagesize: 200,
      page,
    };

    const response = await api.get("cashbackrecoveries", { params });
    data.push(...response.data.recoveries);
    if (page < response.data.pages) {
      return data.concat(await updateRecoveriesList(page + 1));
    }
    return data;
  }

  async function getListAvailableTransactions(): Promise<AvailableCashbackTransaction[]> {
    const data: AvailableCashbackTransaction[] = [];

    if(store.program_type === 1){
      const response = await api.get("availabletransactions");
      data.push(...response.data.transactions);
      return data;
    }
    
    const response = await api.get("availablecashbacktransactions");
    data.push(...response.data.transactions);
    return data;
  }

  const DownloadCsvFileRansomed = async () => {
    try {
      alert("Estamos montando seu relátorio, não atualize ou feche a aba!");
      const recoveredData: Recovery[] = await updateRecoveriesList();
      const data = recoveredData.map(
        ({ customer, value, reversed, date_time }) => ({
          name: `${customer.name} ${customer.surname}`,
          email: customer.email,
          birthday: customer.birthday ? formatDateToDDMMYYYY(customer.birthday.toLocaleString()) : '',
          phone_number: customer.phone_number ?? '',
          customer_document: customer.customer_document,
          value: store.program_type === 1 ? value : `R$ ${applyCurrencyMask(value.toString())}`,
          reversed: reversed ? "Estornado" : "Não Estornado",
          date_time: formatDateToDDMMYYYY(date_time),
        })
      );
      generateCSV(csvHeaderFileRansomed, data, `Relatório de Resgates Realizados - ${formatDate(todayDate.toDateString(), false)}`);
    } catch (error) {
      alert("Erro ao obter os dados de recuperação:" + error);
    }
  };

  const DownloadCsvFileAvailable = async () => {
    try {
      alert("Estamos montando seu relátorio, não atualize ou feche a aba!");
      const recoveredData: AvailableCashbackTransaction[] = await getListAvailableTransactions();
      const data = recoveredData.map(
        ({ name, surname, phone_number,birthday, points_balance, approval_date, cashback_value, email, expiration_date, points, customer_document, transaction_type}) => ({
          name: `${name} ${surname}`,
          customer_document: customer_document,
          email: email,
          phone_number: phone_number ?? '',
          birthday:birthday ? formatDateToDDMMYYYY(birthday.toLocaleString()) : '',
          points_balance: store.program_type === 1 ? points_balance : `R$ ${applyCurrencyMask(points_balance.toString())}`,
          expiration_date: expiration_date ? formatDateToDDMMYYYY(expiration_date.toLocaleString()) : '',
          value: store.program_type === 1 ? points : `R$ ${applyCurrencyMask(cashback_value.toString())}`,
          approval_date: approval_date ? formatDateToDDMMYYYY(approval_date.toLocaleString()) : '',
          transaction_type: transaction_type ?? ''
        })
      );
      generateCSV(csvHeaderFileAvailable, data, `Relatório de Resgates Disponíveis - ${formatDate(todayDate.toDateString())}`);
    } catch (error) {
      alert("Erro ao obter os dados de recuperação:" + error);
    }
  };

  const handleInitialDatePickerChange = (selectedDate: Date): void => {
    setSelectedInitialDate(selectedDate);
    setValidDate(verifyDate(selectedDate, selectedFinalDate))
  };
  const handleFinalDatePickerChange = (selectedDate: Date): void => {
    setSelectedFinalDate(selectedDate);  
    setValidDate(verifyDate(selectedInitialDate, selectedDate))
  };

  const verifyDate = (startDate: Date, endDate: Date) => {
    return startDate.getTime() > endDate.getTime();
} 

  return (
    <SiteWrapper>
      <Page.Content title="Relatórios">
        <Card>
          <Card.Body>
            <Text style={{paddingBottom: "8px"}}>
              <b>Resgates Realizados</b>
            </Text>
            <Text>
              Ao clicar no botão abaixo, um relatório detalhado será gerado
              automaticamente, exibindo informações sobre todos os clientes que
              resgataram cashback.
              Você terá a flexibilidade de escolher um intervalo de datas para
              filtrar os resultados exibidos no relatório.
            </Text>
          </Card.Body>
          <Card.Footer>
            <Form.InputGroup style={{ justifyContent: "space-evenly" }}>
              <Form.Group label="Data Inicial" style={{width: "40%"}}>
                <Form.DatePicker
                  onChange={handleInitialDatePickerChange}
                  value={initialValueDate}
                  minYear={2021}
                  format="dd/mm/yyyy"
                  monthLabels={monthsInPortuguese}
                />
              </Form.Group>
              <Form.Group label="Data Final" style={{width: "40%"}}>
                <Form.DatePicker
                  onChange={handleFinalDatePickerChange}
                  value={initialValueDate}
                  minYear={2021}
                  format="dd/mm/yyyy"
                  monthLabels={monthsInPortuguese}
                />
              </Form.Group>
            </Form.InputGroup>
            <div>
              <Button
                outline
                size="sm"
                RootComponent="a"
                color="secondary"
                onClick={DownloadCsvFileRansomed}
                disabled={validDate}
              >
                Gerar Relatório
              </Button>
            </div>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Body>
          <Text style={{paddingBottom: "8px"}}>
              <b>Resgates Disponíveis</b>
            </Text>
            <Text>
              Ao clicar no botão abaixo, um relatório detalhado será gerado
              automaticamente, exibindo informações sobre todos os clientes que
              possuem cashback disponíveis para resgate.
            </Text>
          </Card.Body>
          <Card.Footer>
            <div>
              <Button
                outline
                size="sm"
                RootComponent="a"
                color="secondary"
                onClick={DownloadCsvFileAvailable}
                disabled={validDate}
              >
                Gerar Relatório
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Page.Content>
    </SiteWrapper>
  );
}

export default ReportsList;
