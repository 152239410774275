import React, { Fragment, useEffect, useState } from "react";

import { useAuth } from "../../hooks/AuthContext";

import api from '../../services/api';
import Settings from "../../interfaces/Settings";
import TransactionTypeStore from "../../interfaces/TransactionTypeStore";
import RecoveryTypeStore from "../../interfaces/RecoveryTypeStore";

import pretty from 'pretty';

import {
  Text
} from "tabler-react";
import Level from "../../interfaces/Level";

const PageGenerate = () => {

  const { store } = useAuth();
  const [settings, setSettings] = useState<Settings>({} as Settings);
  const [transactionTypeList, setTransactionTypeList] = useState('');
  const [recoveryTypeList, setRecoveryTypeList] = useState('');
  const [levelsList, setLevelsList] = useState('');
  const [pointName, setPointName] = useState('');

  useEffect(() => {
    api.get('settings').then(responseSettings => {
      if(responseSettings.data.point_name.slice(-1).toLowerCase() === 's' && responseSettings.data.conversion_factor === 1) {
        setPointName(responseSettings.data.point_name.substring(0, responseSettings.data.point_name.length - 1));
      }
      setSettings(responseSettings.data);
      api.get('transactiontypestores').then(response => {
        let list = '';
        response.data.forEach((data: TransactionTypeStore) => {
          if(data.transactionType.type === 2) {
            list += `<li>${data.transactionType.name} - ${data.points} ${responseSettings.data.point_name}</li>`;
          }
        })
        setTransactionTypeList(list);
      });
      api.get('recoverytypestores').then(response => {
        let list = '';
        response.data.forEach((data: RecoveryTypeStore) => {
          list += `<li>${data.description} (${data.points_needed} ${responseSettings.data.point_name})</li>`;
        })
        setRecoveryTypeList(`<ul>${list}</ul>`);
      });
      api.get('levels').then(response => {
        let list = '';
        response.data.forEach((data: Level) => {
          let extraDescription = '';
          if(!!data.multiplier) {
            extraDescription = ` - ${data.multiplier}% de ${responseSettings.data.point_name} a mais`;
          }
          list += `<li>${data.name} ${extraDescription}</li>`;
        })
        setLevelsList(`<ul>${list}</ul>`);
      });
    });
  }, []);


  const html = pretty(`
    <style type="text/css">
      .head-fidegg-info {
        padding-top: 40px;
        padding-bottom: 40px;
        margin-top: 0;
        margin-bottom: 20px;
        color: ${settings.text_color === 1 ? '#000': '#fff'};
        background-color: ${settings.title_color};
        float: left;
        width: 100%;
      }

      .head-fidegg-info h1 {
        padding-left: 2rem;
      }

      .content-fidegg {
        margin: 1rem;
        color: #252525;
        text-align: center;
      }

      .content-fidegg ul {
        list-style: none;
      }

      .content-fidegg li {
        padding: 2px;
      }

      .content-fidegg div {
        border-top: 1px solid #ebebeb;
        display: block;
        margin: 1rem;
      }
    </style>
    <div class="programa-fidelidade">
      <div class="head-fidegg">
        <div class="head-fidegg-info">
          <h1>
            Programa de Fidelidade ${settings.program_name}
          </h1>
        </div>
      </div>
      <div class="content-fidegg">
        <div class="sign-up">
          <h2>Como participar</h2>
          <p>Ao fazer cadastro no site da ${store.name} você automaticamente já está cadastrado e participando do programa.</p>
          <p>Seu saldo de pontos, botão para recuperar recompensas, nível e formas de ganhar pontos ficam disponível em um widget que aparece em todas as páginas da loja.</p>
        </div>
        <div class="how-to-win">
          <h2>Como ganhar ${settings.point_name}</h2>
          <ul>
            <li>Ganhe ${settings.conversion_factor} ${pointName} para cada R$1,00 em compras</li>
            ${transactionTypeList}
          </ul>
        </div>
        <div class="rewards">
          <div class="rewards-list">
            <h2>Fazendo parte do programa de fidelidade da ${store.name} você terá os seguintes benefícios:</h2>
            ${recoveryTypeList}
          </div>
        </div>
        <div class="levels">
          <h2>Ao acumular pontos você sobe de nível e tem mais benefícios</h2>
          ${levelsList}
        </div>
      </div>
    </div>
  `);

  return (
    <Fragment>
      <pre>
        <Text>Copie o código abaixo e crie uma página na sua loja com ele:</Text>
        <br/>
        <code>
          {html}
        </code>
      </pre>
    </Fragment>
  )
}

export default PageGenerate;