import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';

import Moment from 'react-moment';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';
import CashbackRecovery from '../../interfaces/CashbackRecovery';

import {
  Page,
  Card,
  Table,
  Text,
  Button,
  Form,
} from "tabler-react";
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';

function CashbackRecoveriesList() {
  const history = useHistory();

  const [recoveries, setRecoveries] = useState([]);
  const [documentSearch, setDocumentSearch] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [surnameSearch, setSurnameSearch] = useState('');
  const [page, setPage]= useState(1);
  const [pageCount, setPageCount]= useState(0);

  useEffect(() => {
    updateRecoveriesList();
  }, [page]);

  async function updateRecoveriesList() {
    const params = {
      page,
      name: nameSearch,
      surname: surnameSearch,
      document: documentSearch
    };
    await api.get('cashbackrecoveries', { params }).then(response => {
      setRecoveries(response.data.recoveries);
      setPageCount(response.data.pages);
    });
  }

  function handleRegenerateClick(recovery: CashbackRecovery) {
    history.push(`/regeneratecoupon/form/${recovery.id}`);
  }

  function handleEditClick(recovery: CashbackRecovery) {
    alert('Ainda não disponível');
  }

  async function handleSubmitSearch(event: FormEvent) {
    event.preventDefault();

    await updateRecoveriesList();
  }

  function handleDocumentChange(event: ChangeEvent<HTMLInputElement>) {
    setDocumentSearch(event.target.value);
  }

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setNameSearch(event.target.value);
  }

  function handleSurnameChange(event: ChangeEvent<HTMLInputElement>) {
    setSurnameSearch(event.target.value);
  }

  const handlePaginate = useCallback((selectedItem: { selected: number }) => {
    setPage(selectedItem.selected + 1);
  }, []);

  return (
    <SiteWrapper>
      <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Resgates de Cashback</Card.Title>
            <Card.Options>
              <Form onSubmit={handleSubmitSearch}>
                <Form.InputGroup>
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Nome"
                    name="name"
                    onChange={handleNameChange}
                  />
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Sobrenome"
                    name="name"
                    onChange={handleSurnameChange}
                  />
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Documento"
                    name="document"
                    onChange={handleDocumentChange}
                  />
                  <span className="input-group-btn ml-2">
                    <Button
                      size="sm"
                      color="default"
                      type="submit"
                      icon="search"
                    />
                  </span>
                </Form.InputGroup>
              </Form>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Cliente"},
              { content: "Documento"},
              { content: "Valor" },
              { content: "Data" },
              { content: "Estornado" },
              { content: "ID do Cupom" },
              { content: null },
            ]}
            bodyItems={
              recoveries.map((recovery: CashbackRecovery) => {
                return {
                  key: recovery.id,
                  item: [
                    {
                      content: (
                        <Text>
                          {recovery.customer.name} {recovery.customer.surname}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {recovery.customer.customer_document}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {recovery.value}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Moment format="DD/MM/YYYY HH:mm:ss">
                          {recovery.date_time}
                        </Moment>
                      ),
                    },
                    {
                      content: (
                        <Form.Checkbox
                          label=" "
                          className="ml-5"
                          checked={recovery.reversed}
                          disabled={true}
                        />
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {recovery.recovery_platform_response?.id}
                        </Text>
                      ),
                    },
                    {
                      alignContent: "right",
                      content: (
                        <>
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="rotate-cw"
                            onClick={() => handleRegenerateClick(recovery)}
                          />
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(recovery)}
                          />
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
          <Card.Footer>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePaginate}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </Card.Footer>
        </Card>
      </Page.Content>
    </SiteWrapper>
  );
}

export default CashbackRecoveriesList;