import React, { useState, ChangeEvent, useCallback, FormEvent, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import { TransactionStatusList } from '../../interfaces/Transaction';

import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";

function TransactionsForm() {

  const history = useHistory();

  const { id } = useParams();

  const initialValues = {
    customer_document: "",
    points: "",
    status: 1,
  };

  const [transaction, setTransaction] = useState(initialValues);

  useEffect(() => {
    if(!!id) {
      api.get(`transactions/${id}`).then(response => {
        setTransaction(response.data);
      });
    }
  }, [id]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setTransaction({...transaction, [name]: value});
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        const data = {
          "customer_document": transaction.customer_document,
          "points": transaction.points,
          "status": transaction.status
        }

        if(!id){
          await api.post('transactions', data)
          alert('Transação incluída!');
        } else {
          await api.put(`transactions/${id}`, data)
          alert('Transação alterada!');
        }

        history.push('/transactions');
      } catch (err) {
        alert('Erro ao incluir transação!');
      }
    }, [history, transaction]
  );

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target;
    setTransaction({...transaction, [name]: parseInt(value)});
  }

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Transação"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            { (!id) &&
              <Form.Group label="CPF do cliente (formatado)">
                <Form.Input
                  name="customer_document"
                  value={transaction.customer_document || ""}
                  onChange={handleChange}
                />
              </Form.Group>
            }
            <Form.Group label="Situação">
              <Form.Select
                  value={transaction.status}
                  onChange={handleSelectChange}
                  name="status"
                >
                  { TransactionStatusList.map((status) => (
                    <option
                      key={status.id}
                      value={status.id}
                    >
                      { status.value }
                    </option>
                  )) }
              </Form.Select>
            </Form.Group>
            <Form.Group label="Pontos">
              <Form.Input
                name="points"
                value={transaction.points || ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default TransactionsForm;