import React, { FormEvent, useCallback, useState } from 'react';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";
import { useHistory } from 'react-router';

interface UploadResponse {
  success: boolean;
  msg: string;
}

function ImportVipsForm() {

  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState<File>();

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        if(selectedFile) {
          event.preventDefault();
          const data = new FormData();
          data.append('vipcustomers', selectedFile);

          const response = await api.post(`vipcustomersfileupload`, data);
          const responseData = response.data as UploadResponse;
          if(responseData.success) {
            alert('Importação efetuada com sucesso')
            history.goBack();
          } else {
            alert('Erro ao efetuar importação')
          }
        } else {
          alert('Selecione um arquivo');
        }
      } catch (err) {
        alert('Erro ao importar arquivo');
      }
    }, [history, selectedFile]
  )

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleChangeFile = useCallback(event => {
    if(!!event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  }, [selectedFile])

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Cliente"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Importar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Arquivo">
              <Form.FileInput
                name="customersfile"
                onChange={handleChangeFile}
                accept=".csv"
                label="Selecione o arquivo"
              />
            </Form.Group>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  )
}

export default ImportVipsForm;