import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import Level from '../../interfaces/Level';

import {
  Page,
  Button,
  Form,
  Card,
  Icon
} from "tabler-react";

function LevelsForm() {

  const history = useHistory();

  const { id } = useParams();

  const initialValues: Level = {
    id: "",
    name: "",
    points_needed: "",
    multiplier: ""
  };

  const [level, setLevel] = useState<Level>(initialValues);

  useEffect(() => {
    if(!!id) {
      api.get(`levels/${id}`).then(response => {
        setLevel(response.data);
      });
    }
  }, [id]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setLevel({...level, [name]: value});
  }

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        const data = {
          "name": level.name,
          "points_needed": level.points_needed,
          "multiplier": level.multiplier
        }

        if(!!level.id) {
          await api.put(`levels/${level.id}`, data)
          alert('Nível atualizado!');
        } else {
          await api.post('levels', data)
          alert('Nível incluído!');
        }

        history.push('/levels');
      } catch (err) {
        if(!!level.id) {
          alert('Erro ao atualizar o nível!');
        } else {
          alert('Erro ao cadastrar o nível!');
        }
      }
    }, [history, level]
  );

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Nível"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Nome">
              <Form.Input
                name="name"
                value={level.name || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Pontos Necessários">
              <Form.Input
                name="points_needed"
                value={level.points_needed || ""}
                onChange={handleChange}
                type="number"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Multiplicador
                <Icon name="help-circle" style={{marginLeft:'5px'}} title="Percentual para que, quando informado, os usuário deste nível tenham mais vantagem na obtenção de pontos" />
              </Form.Label>
              <Form.Input
                name="multiplier"
                value={level.multiplier || ""}
                onChange={handleChange}
                type="number"
              />
            </Form.Group>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default LevelsForm;