import React, { createContext, useCallback, useState, useContext } from 'react';

import api from '../services/api';
import Store from '../interfaces/Store';

interface SignInCredentials {
  email?: string;
  password?: string;
  id?: number;
  isMonitor?: boolean;
  newPassword?: string;
  codeTotp?: string;
}

interface signInMonitorCredentials{
  email?: string;
  password?: string;
  secret?: string
}

interface StoreData {
  id?: number,
  email?: string,
  name?: string,
  cnpj?: string,
  program_type?: number,
  platform_id?: number,
  isMonitor?: boolean,
  twoFactorAuthentication?: boolean,
  newPasswordRequired?: boolean,
}

interface AuthContextData {
  store: StoreData;
  storeList: Store[];
  expiration: string;
  signIn(credentials: SignInCredentials): Promise<boolean>;
  signInMonitor(credentials: signInMonitorCredentials): Promise<boolean>;
  signOut(): void;
}

interface AuthState {
  token?: string;
  expiration?: string;
  store?: object;
  storeList?:[];
}

const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@fidegg:token');
    const store = localStorage.getItem('@fidegg:store');
    const expiration = localStorage.getItem('@fidegg:token-expiration');
    const storeList = localStorage.getItem('@fidegg:storeList')
    if(token && expiration && store) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, expiration, store: JSON.parse(store), storeList: JSON.parse(storeList!) };
    }
    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password, id, isMonitor = false, newPassword, codeTotp }) => {
    let response;

    if(isMonitor){
      const tokenMonitor = localStorage.getItem('@fidegg:tokenMonitor');
      api.defaults.headers.authorization = `Bearer ${tokenMonitor}`;

      const params = {
        id: id
      }
      response = await api.get('storebyid', { params });
    }else {
        response = await api.post('auth', {
        email: email,
        password: password,
        newPassword:newPassword,
        codeTotp:codeTotp
      });
    }

    const { token, store, expiration, newPasswordRequired, twoFactorAuthentication} = response.data;

    const validStore = store || {}
    validStore.newPasswordRequired = newPasswordRequired;
    validStore.twoFactorAuthentication = twoFactorAuthentication;
    validStore.isMonitor = isMonitor;

    if((newPasswordRequired || twoFactorAuthentication) && !token) {
      setData({store: validStore})
      return true
    };

    store.isMonitor = isMonitor;
    if(token) {
      localStorage.setItem('@fidegg:token', token);
      localStorage.setItem('@fidegg:token-expiration', expiration);
      localStorage.setItem('@fidegg:store', JSON.stringify(store));
      const storeList =localStorage.getItem('@fidegg:storeList')
      
      api.defaults.headers.authorization = `Bearer ${token}`;
      setData({token, expiration, store: validStore, storeList: JSON.parse(storeList!)})
      return true;
    }
    return false;
  }, []);

  const signInMonitor = useCallback(async ({ email, password, secret }) => {
    const response = await api.post('auth/monitor', {
      email: email,
      password: password,
      secret: secret
    });

    const { tokenMonitor, user, expirationToken, twofactorauthentication } = response.data;
    if(twofactorauthentication) {return true};

    if(tokenMonitor) {
      localStorage.setItem('@fidegg:tokenMonitor', tokenMonitor);
      localStorage.setItem('@fidegg:expiration-login', expirationToken);
      localStorage.setItem('@fidegg:user', JSON.stringify(user));

      api.defaults.headers.authorization = `Bearer ${tokenMonitor}`;
      await api.get("storesadmin").then((response) => {  
          localStorage.setItem('@fidegg:storeList', JSON.stringify(response.data));
          setData({storeList: response.data});
        });

      return true;
    }
    return false;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@fidegg:token');
    localStorage.removeItem('@fidegg:token-expiration');
    localStorage.removeItem('@fidegg:store');
    localStorage.removeItem('@fidegg:tokenMonitor');
    localStorage.removeItem('@fidegg:expiration-login');
    localStorage.removeItem('@fidegg:store')
    localStorage.removeItem('@fidegg:storeList')
    api.defaults.headers.authorization = null;

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider value={{store: data.store!, expiration: data.expiration!, signIn, signOut, signInMonitor, storeList: data.storeList!}} >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if(!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };