import React, { ChangeEvent, useEffect, useState } from "react";
import { Formik } from "formik";
import { Button, Form, Grid, Site, LoginPage as TablerLoginPage } from "tabler-react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/AuthContext";
import Store, { storeStatusDescription } from "../../interfaces/Store";

import logo from "../../assets/img/logo.svg";
import "./loginMonitor.css";


function LoginMonitor() {
  const history = useHistory();

  const { signIn, signInMonitor, signOut, storeList } = useAuth();
  function handleSignOut() {
    try {
      signOut();
      history.push("/");
    } catch (err) {
      alert("Erro ao efetuar logout");
    }
  }

  const [stores, setStores] = useState<Store[]>();
  const [backUpStores, setbackUpStores] = useState<Store[]>([]);
  const [emailMagazord, setEmailMagazord] = useState<string>()
  const [passwordMagazord, setPasswordMagazord] = useState<string>()
  const [code, setCode]= useState<string>()
  const [selected, setSelected] = useState<number>(-1);
  const [twoFactor , settwoFactor] = useState(false);

 useEffect(() =>{
    if(!!storeList){
      setStores(storeList);
      setbackUpStores(storeList);
      setSelected(typeof storeList[0]?.id === "number" ? storeList[0].id : -1);
    }
 }, [storeList])

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    let storesFilter = backUpStores?.filter((e) =>
      e.name.toLocaleLowerCase().includes(event.target.value)
    );
    setStores(storesFilter);
    setSelected(
      storesFilter.length > 0 && typeof storesFilter[0].id === "number"
        ? storesFilter[0].id
        : -1
    );
  }

  function handleChangeSelected(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setSelected(parseInt(value));
  }

  async function handleSignInStore () {
    await signIn({
     id: selected,
     isMonitor: true,
      });
    history.push("dashboard");
  }

  async function handleLogin (email?: string, password?: string) {
    try { 
      await signInMonitor({
        email: emailMagazord ?? email,
        password: password? password : passwordMagazord!,
        secret: code ?? "1",
      });

      if (!!email && !!password){
        setEmailMagazord(email)
        setPasswordMagazord(password)
      }
      settwoFactor(true); 
    } catch (err) {
      alert("Login ou senha incorretos");
    } 
  }
  
  return (
    <>
      <Button.List className="button-out">
        <Button
          href="#"
          outline
          size="sm"
          RootComponent="a"
          color="primary"
          pill
          onClick={handleSignOut}
        >
          Sair
        </Button>
      </Button.List>
      <Formik
        initialValues={{
          email: "",
          password: "",
          name: "",
        }}
        validate={(values) => {
          let errors: any = {};
          if (!values.email) {
            errors.email = "Obrigatório";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Endereço de e-mail inválido";
          }
          return errors;
        }}
        onSubmit={async (
          values,
          { setSubmitting, setErrors /* setValues and other goodies */ }
        ) => {
            handleLogin(values.email, values.password);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            {stores ? 
              <Grid.Col className="grid-store">
                <Site.Header
                  href="/"
                  alt="Fidegg"
                  imageURL="/img/logo.svg"
                  align="center"
                  />
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  handleSignInStore(); 
                }}>
                  <Form.Group label="Lista de lojas" className="form-store">
                    <Form.Input
                      placeholder="Buscar por nome da loja ..."
                      onChange={handleNameChange}
                    />
                    <Form.Select
                      height={"31px"}
                      onChange={handleChangeSelected}
                    >
                      {stores!.map((store) => (
                        <option key={store.id} value={store.id}>
                          {store.id +
                            " - " +
                            store.name +
                            " - " +
                            storeStatusDescription(store.status!)}
                        </option>
                      ))}
                    </Form.Select>
                    <Button
                      size="sm"
                      href="#"
                      RootComponent="a"
                      color="primary"  
                      onClick={handleSignInStore}
                      className="button-store">
                      Login
                    </Button>
                  </Form.Group> 
                </Form>
              </Grid.Col>
            : 
            <>
              {twoFactor && !storeList? 
                <Grid.Col className="grid-store">
                  <Site.Header
                    href="/"
                    alt="Fidegg"
                    imageURL="/img/logo.svg"
                    align="center"
                    />
                  <Form.Group label="Autenticação" className="form-store">
                    <Form.Input
                      placeholder="Código 2FA"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setCode(event.target.value)}
                    />
                    <Button
                      size="sm"
                      href="#"
                      RootComponent="a"
                      color="primary"  
                      onClick={handleLogin}
                      className="button-store">
                      Login
                    </Button>
                  </Form.Group> 
                </Grid.Col>
                : 
                <TablerLoginPage
                  onSubmit={handleSubmit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  logoUrl={logo}
                  strings={{
                    title:"Login Magazord",
                    buttonText:"Login",
                    emailLabel:"Email Magazord",
                    emailPlaceholder:"Email Magazord",
                    passwordLabel:"Senha",
                    passwordPlaceholder:"Senha",
                  }}
                />
              }
            </>
            }
          </>
        )}
      />
    </>
  );
}

export default LoginMonitor;
