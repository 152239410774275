import React, { FormEvent, useCallback } from 'react';
import { useHistory } from 'react-router';

import SiteWrapper from "../../base/SiteWrapper";

import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import api from '../../services/api';

import {
  Page,
  Button,
  Form,
  Card,
  Grid
} from "tabler-react";

const StripeForm= () => {
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault();

      if(!!elements) {
        const cardElement = elements.getElement(CardNumberElement);
        if(!!stripe && !!cardElement) {
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
          })

          if(!error) {
            const data = {
              payment_method_id: paymentMethod?.id,
            }

            await api.post('subscriptions', data)
            alert('Forma de pagamento incluída!');
          } else {
            alert('Erro ao salvar dados de pagamento!');
          }
        }
      }
    } catch (err) {
      alert('Erro ao salvar dados de pagamento!');
    }
  };

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  return (
    <Form onSubmit={submit => handleSubmit(submit)}>
      <Page.Card
        title="Dados de Pagamento"
        footer={
          <Card.Footer>
            <div className="d-flex">
              <Button link onClick={handleClickCancel}>Cancelar</Button>
              <Button type="submit" disabled={!stripe} color="primary" className="ml-auto">
                Salvar
              </Button>
            </div>
          </Card.Footer>
        }
      >
        <Form.FieldSet>
          <Grid.Row>
            <Grid.Col sm={12} lg={12}>
              <Form.Group label="Número do Cartão">
              <CardNumberElement
                options={{
                  style: {
                    base: {
                      backgroundColor: '#fff',
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                  showIcon: true,
                }}
              />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col sm={12} lg={6}>
              <Form.Group label="Data de Expiração">
              <CardExpiryElement
                options={{
                  style: {
                    base: {
                      backgroundColor: '#fff',
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
              />
              </Form.Group>
            </Grid.Col>
            <Grid.Col sm={12} lg={6}>
              <Form.Group label="CVC">
              <CardCvcElement
                options={{
                  style: {
                    base: {
                      backgroundColor: '#fff',
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#ddd',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
              />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Form.FieldSet>
      </Page.Card>
    </Form>
  )
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ? process.env.REACT_APP_STRIPE_PUBLIC_KEY : '');

const SubscriptionForm = () => {
  return (
    <SiteWrapper>
      <Elements stripe={stripePromise}>
        <StripeForm />
      </Elements>
    </SiteWrapper>
  )
}

export default SubscriptionForm;