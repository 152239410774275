import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import SiteWrapper from "../../base/SiteWrapper";

import ReactPaginate from 'react-paginate';

import api from '../../services/api';

import {
  Page,
  Button,
  Card,
  Text,
  Table
} from "tabler-react";
import Product from '../../interfaces/Product';

function ProductsList() {

  const history = useHistory();

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    updateProductsList();
  }, [page]);

  function updateProductsList() {
    const params = {
      page
    }
    api.get('products', { params }).then((response: any) => {
      setProducts(response.data.products);
      setPageCount(response.data.pages);
    });
  }

  function handleEditClick(product: Product) {
    history.push(`/products/form/${product.id}`);
  }

  function handleClickAdd() {
    history.push('/products/form');
  }

  const handleDeleteClick = useCallback(
    async (product: Product) => {
      try {
        if (window.confirm("Confirma a exclusão da configuração de produto?")) {
          await api.delete(`products/${product.id}`);

          alert('Configuração de Produto Excluída!');

          updateProductsList();
        }
      } catch (err) {
        alert('Erro ao excluir configuração!');
      }
    }, []);

  const handlePaginate = useCallback((selectedItem: { selected: number }) => {
    setPage(selectedItem.selected + 1);
  }, []);

  return (
    <SiteWrapper>
      <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Configurações por Produtos</Card.Title>
            <Card.Options>
              <Button
                RootComponent="a"
                color="secondary"
                size="sm"
                icon="plus"
                onClick={handleClickAdd}
              >
                Incluir
              </Button>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Descrição" },
              { content: "Valor" },
              { content: null },
            ]}
            bodyItems={
              products.map((product: Product) => {
                return {
                  key: product.id,
                  item: [
                    {
                      content: (
                        <Text>
                          {product.description}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {product.value}
                        </Text>
                      ),
                    },
                    {
                      alignContent: "right",
                      content: (
                        <>
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(product)}
                          />
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="trash"
                            onClick={() => handleDeleteClick(product)}
                          />
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
          <Card.Footer>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePaginate}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </Card.Footer>
        </Card>
      </Page.Content>
    </SiteWrapper>
  );
}

export default ProductsList;