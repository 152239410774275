import React, { ChangeEvent, useEffect, useState } from "react";
import { Formik } from "formik";
import { Button, LoginPage as TablerLoginPage, StandaloneFormPage, Text, Form, Card } from "tabler-react";
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from "../../hooks/AuthContext";
import { validatePassword } from "../../utils/validatePassword";

import logo from "../../assets/img/logo.svg";

import './login.css'

type Props = {};

function Login(props: Props) {

  const history = useHistory();
  const [emailCognito, setEmailCognito] = useState<string>()
  const [passwordCognito, setPasswordCognito] = useState<string>()
  const [newPassword, setNewPassword] = useState<string>("")
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>(" ")
  const [newPasswordRequired , setNewPasswordRequired] = useState(false);
  const [twoFactorAuth , setTwoFactorAuth] = useState(false);
  const [disableButton , setDisableButton] = useState(true);
  const [code, setCode]= useState<string>()
  const [passwordError, setPasswordErr] = useState<string[]>([]);

  const { signIn, store } = useAuth();

  useEffect(() => {
    if (passwordError.length === 0 && !!newPassword) {setDisableButton(false)};
    if(!!store && store.id === undefined && (store.twoFactorAuthentication || store.newPasswordRequired)){
      setNewPasswordRequired(store.newPasswordRequired!);
      setTwoFactorAuth(store.twoFactorAuthentication!) 
    }
  }, [passwordError, newPassword, store]);

  async function handleLogin (email?: string, password?: string) {
    try { 
      await signIn({
        email: emailCognito ?? email,
        password: password? password : passwordCognito,
        newPassword: newPassword,
        codeTotp: code
      });

      if (!!email && !!password){
        setEmailCognito(email)
        setPasswordCognito(password)
      }
      if(!!store && !!store.id){history.push('dashboard');}
    } catch (err) {
      alert("Login ou senha incorretos");
    } 
  }
 
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newPasswordValue = event.currentTarget.value;
    setNewPassword(newPasswordValue);
    setPasswordErr(validatePassword(newPasswordValue, confirmNewPassword));
  };

  const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const confirmNewPasswordValue = event.currentTarget.value;
    setConfirmNewPassword(confirmNewPasswordValue);
    setPasswordErr(validatePassword(newPassword, confirmNewPasswordValue));
  };

  const formattedPasswordErrors = passwordError.map((message, index) => (
    <Text key={index} color={"red"}>{message}</Text>
  ));

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validate={values => {
        // same as above, but feel free to move this into a class method now.
        let errors: any = {};
        if (!values.email) {
          errors.email = "Obrigatório";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Endereço de e-mail inválido";
        }
        return errors;
      }}
      onSubmit={async (
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {

        try {
          await handleLogin(values.email, values.password);
        } catch (err) {
          alert('Login ou senha incorretos');
        }

      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          {newPasswordRequired ? 
            <StandaloneFormPage
              imageURL={logo}
              children={
                <Form className="form-name">
                <Form.Group label="Nova Senha" className="form-reset">
                      <Form.Input
                          placeholder="Digite sua nova senha"
                          name="password"
                          onChange={handlePasswordChange}
                          error={passwordError.length > 0 ? " " : ""}
                          type="password"
                      />
                  </Form.Group>
                  <Form.Group label="Digite a nova senha novamente" className="form-reset">
                      <Form.Input
                          placeholder="Digite sua nova senha novamente"
                          onChange={handleConfirmPasswordChange}
                          name="confirmPassword"
                          error={passwordError.length > 0 ? " " : ""}
                          type="password"
                      />
                  </Form.Group>
                {formattedPasswordErrors}
                <Button
                  size="sm"
                  href="#"
                  RootComponent="a"
                  color="primary"  
                  onClick={handleLogin}
                  className="button-store"
                  disabled={disableButton}
                  >
                  Login
                </Button>
              </Form> 
              }
            />
          : 
          <>
          {twoFactorAuth ?
            <StandaloneFormPage
              imageURL={logo}
              children={
                <Form 
                onSubmit={(e) => {
                  e.preventDefault();
                  handleLogin(); 
                }}
                className="form-name"
                >
                <Form.Group 
                label="Autenticação" 
                className="form-reset"
                >
                      <Form.Input
                        placeholder="Código 2FA"
                        name="password"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setCode(event.target.value)}
                      />
                  </Form.Group>
                <Button
                  size="sm"
                  href="#"
                  RootComponent="a"
                  color="primary"  
                  onClick={handleLogin}
                  className="button-store"
                  disabled={!code}
                  >
                  Login
                </Button>
              </Form> 
              }
            /> 
            :
            <>
              <TablerLoginPage
                onSubmit={handleSubmit}
                onChange={handleChange}
                onBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
                logoUrl={logo}
                strings={{
                  title:"Login",
                  buttonText:"Login",
                  emailLabel:"E-Mail",
                  emailPlaceholder:"E-Mail",
                  passwordLabel:"Senha",
                  passwordPlaceholder:"Senha",
                }}
              />
                <div className="text-center">
                  <Link style={{fontSize:"14px"}} to="/forgot-password">Esqueceu sua senha?</Link>
                </div>
              <Button.List style={{ justifyContent: "center", display: "flex" }}>
                <Button
                  className="w-15"
                  color="default"
                  RootComponent="a"
                  href="/login-monitor"
                  style={{ color: "blue", fontSize: "11px", fontWeight: "100" }}
                >
                  Login interno Magazord
                </Button>
              </Button.List>
              <Card.Body style={{ display: "flex", textAlign: "center", justifyContent: "center", marginTop: "50px"}}>
                <Text style={{width: "20%",backgroundColor: "#FF0000", color: "white", padding: "20px", borderRadius:"30px"}}>
                  **Importante: Altere sua senha !<br/>
                  Para melhorar a segurança da nossa plataforma, atualizamos nosso sistema de autenticação. 
                  Isso significa que você precisará redefinir sua senha para continuar acessando sua conta Fidegg.  
                  Basta clicar no link 'Esqueceu sua senha?' acima.    
                </Text>
              </Card.Body>
            </>
          }
          </>
        }
      </>
    )}
  />
);
}

export default Login;