import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';
import TransactionTypeStore from '../../interfaces/TransactionTypeStore';
import { useAuth } from '../../hooks/AuthContext';

import {
  Page,
  Card,
  Table,
  Text,
  Button
} from "tabler-react";

function TransactionTypesList() {

  const history = useHistory();

  const { store } = useAuth();

  const [transactionTypeStores, setTransactionTypeStores] = useState([]);

  useEffect(() => {
    api.get('transactiontypestores').then(response => {
      setTransactionTypeStores(response.data);
    });
  }, []);

  function handleClickAdd() {
    history.push('/transactiontypes/form');
  }

  function handleEditClick(transactionTypeStore: TransactionTypeStore) {
    history.push(`/transactiontypes/form/${transactionTypeStore.id}`);
  }

  const pointsLabel = (store.program_type === 1) ? 'Pontos' : 'Valor'

  return (
    <SiteWrapper>
      <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Fatos Geradores</Card.Title>
            <Card.Options>
            <Button
                RootComponent="a"
                color="secondary"
                size="sm"
                icon="plus"
                onClick={handleClickAdd}
              >
                Incluir
              </Button>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Tipo", className: "w-1" },
              { content: pointsLabel },
              { content: "Limite de Uso" },
              { content: "Ativo" },
              { content: null },
            ]}
            bodyItems={
              transactionTypeStores.map((transactionTypeStore: TransactionTypeStore) => {
                return {
                  key: transactionTypeStore.id,
                  item: [
                    {
                      content: (
                        <Text>
                          {transactionTypeStore.transactionType.name}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {transactionTypeStore.points}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {transactionTypeStore.usage_limit}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          { transactionTypeStore.active ? 'Sim' : 'Não'}
                        </Text>
                      ),
                    },
                    {
                      alignContent: "right",
                      content: (
                        <>
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(transactionTypeStore)}
                          />
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
        </Card>
      </Page.Content>
    </SiteWrapper>
  );
}

export default TransactionTypesList;