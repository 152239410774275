const SettingsPositionList = [
  {id: 1, value: 'Inferior Direita'},
  {id: 2, value: 'Inferior Esquerda'},
  {id: 3, value: 'Superior Direita'},
  {id: 4, value: 'Superior Esquerda'},
  {id: 5, value: 'Meio Direita'},
  {id: 6, value: 'Meio Esquerda'},
];

const SettingsTextColorList = [
  {id: 1, value: 'Preto'},
  {id: 2, value: 'Branco'},
];

const settingsPositionDescription = (positionId: number) => {
 return SettingsPositionList.find(position => position.id === positionId)?.value;
}

const settingsTextColorDescription = (colorId: number) => {
 return SettingsTextColorList.find(color => color.id === colorId)?.value;
}

export { SettingsPositionList, settingsPositionDescription, SettingsTextColorList, settingsTextColorDescription };

export default interface Settings {
  id: number | "";
  expiration_days: number | "";
  conversion_factor: number | "";
  cashback_percent_vip: number | "";
  program_name: string;
  point_name: string;
  token: string;
  token_api: string;
  store_url: string;
  base_color: string;
  title_color: string;
  facebook_url: string;
  instagram_url: string;
  twitter_url: string;
  consider_freight_order: boolean;
  position_desktop: number | "";
  position_mobile: number | "";
  text_color: number | "";
  open_popup: boolean;
  consider_marketplace_orders: boolean;
  program_logo_name: string;
  program_logo_url: string;
  grace_days: number | "";
  minimum_cashback_recovery: number | "";
  minimum_cashback_order: number | "";
  reply_to_email: string;
}