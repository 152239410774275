import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';
import CashbackTransaction from '../../interfaces/CashbackTransaction';

import {
  Page,
  Card,
  Table,
  Text,
  Button,
  Form
} from "tabler-react";

function CashbackTransactionsList() {

  const history = useHistory();

  const [transactions, setTransactions] = useState([]);
  const [documentSearch, setDocumentSearch] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [surnameSearch, setSurnameSearch] = useState('');
  const [orderCodeSearch, setOrderCodeSearch] = useState('');
  const [page, setPage]= useState(1);
  const [pageCount, setPageCount]= useState(0);

  useEffect(() => {
    updateTransactionsList();
  }, [page]);

  async function updateTransactionsList() {
    const params = {
      page,
      name: nameSearch,
      surname: surnameSearch,
      document: documentSearch,
      order_code: orderCodeSearch
    };
    await api.get('cashbacktransactions', { params }).then(response => {
      setTransactions(response.data.transactions);
      setPageCount(response.data.pages);
    });
  }

  function handleEditClick(transaction: CashbackTransaction) {
    history.push(`/cashbacktransactions/form/${transaction.id}`);
  }

  function handleClickAdd() {
    history.push('/cashbacktransactions/form');
  }

  async function handleSubmitSearch(event: FormEvent) {
    event.preventDefault();

    await updateTransactionsList();
  }

  function handleOrderCodeChange(event: ChangeEvent<HTMLInputElement>) {
    setOrderCodeSearch(event.target.value);
  }

  function handleDocumentChange(event: ChangeEvent<HTMLInputElement>) {
    setDocumentSearch(event.target.value);
  }

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setNameSearch(event.target.value);
  }

  function handleSurnameChange(event: ChangeEvent<HTMLInputElement>) {
    setSurnameSearch(event.target.value);
  }

  function getStatusDescription(status: number) {
    switch(status) {
      case 2:
        return "Aprovado";
      case 3:
        return "Cancelado";
      default:
        return "Pendente";
    }
  }

  const handlePaginate = useCallback((selectedItem: { selected: number }) => {
    setPage(selectedItem.selected + 1);
  }, []);

  const handleDeleteClick = useCallback(
    async (transaction: CashbackTransaction) => {
      try {
        if(window.confirm("Confirma a exclusão da transação?")) {
          await api.delete(`cashbacktransactions/${transaction.id}`);

          alert('Transação excluída!');
          updateTransactionsList();
        }
      } catch (err) {
        alert('Erro ao excluir transação!');
      }
  }, []);

  return (
    <SiteWrapper>
      <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Transações</Card.Title>
            <Card.Options>
              <Form onSubmit={handleSubmitSearch}>
                <Form.InputGroup>
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Nome"
                    name="name"
                    onChange={handleNameChange}
                  />
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Sobrenome"
                    name="name"
                    onChange={handleSurnameChange}
                  />
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Documento"
                    name="document"
                    onChange={handleDocumentChange}
                  />
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Código Pedido"
                    name="order_code"
                    onChange={handleOrderCodeChange}
                  />
                  <span className="input-group-btn ml-2">
                    <Button
                      size="sm"
                      color="default"
                      type="submit"
                      icon="search"
                    />
                  </span>
                </Form.InputGroup>
              </Form>
              <Button RootComponent="a" color="secondary" size="sm" icon="plus" onClick={handleClickAdd}>
                Incluir
              </Button>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Cliente"},
              { content: "Documento"},
              { content: "Tipo" },
              { content: "Valor" },
              { content: "Cashback" },
              { content: "Status" },
              { content: "Código Pedido" },
              { content: "Data" },
              { content: null },
            ]}
            bodyItems={
              transactions.map((transaction: CashbackTransaction) => {
                return {
                  key: transaction.id,
                  item: [
                    {
                      content: (
                        <Text>
                          {transaction.customer.name} {transaction.customer.surname}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {transaction.customer.customer_document}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {transaction.transactionType.name}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {transaction.value}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {transaction.cashback_value}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {getStatusDescription(transaction.status)}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {transaction.order_code}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Moment format="DD/MM/YYYY HH:mm:ss">
                          {transaction.date_time}
                        </Moment>
                      ),
                    },
                    {
                      alignContent: "right",
                      content: (
                        <>
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(transaction)}
                          />
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="trash"
                            onClick={() => handleDeleteClick(transaction)}
                          />
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
          <Card.Footer>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePaginate}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </Card.Footer>
        </Card>
      </Page.Content>
    </SiteWrapper>
  );
}

export default CashbackTransactionsList;