import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';
import { useAuth } from '../../hooks/AuthContext';
import TransactionTypeStore from '../../interfaces/TransactionTypeStore';
import TransactionType from '../../interfaces/TransactionType';

import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";

function TransactionTypesForm() {

  const history = useHistory();

  const { store } = useAuth();

  const { id } = useParams();

  const initialValues: TransactionTypeStore = {
    id: "",
    active: "",
    points: "",
    usage_limit: "",
    transactionType: {
      id: "",
      type: "",
      name: ""
    }
  };

  const [transactionType, setTransactionType] = useState<TransactionTypeStore>(initialValues);
  const [defaultTypes, setDefaultTypes] = useState([]);

  const pointsLabel = (store.program_type === 1) ? 'Pontos' : 'Valor'

  useEffect(() => {
    api.get('transactiontypes').then(response => {
      setDefaultTypes(response.data);
      setTransactionType({...transactionType, transactionType: response.data[0]});
    });
    if(!!id) {
      api.get(`transactiontypestores/${id}`).then(response => {
        setTransactionType(response.data);
      });
    }
  }, [id]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setTransactionType({...transactionType, [name]: value});
  }

  function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    setTransactionType({...transactionType, [name]: checked});
  }

  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    const newTransactionType = {
      id: parseInt(value)
    } as TransactionType;
    setTransactionType({...transactionType, transactionType: newTransactionType});
  }

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        const data = {
          "points": transactionType.points,
          "usage_limit": transactionType.usage_limit,
          "active": transactionType.active,
          "transaction_type_id": transactionType.transactionType.id
        }

        if(!!transactionType.id) {
          await api.put(`transactiontypestores/${transactionType.id}`, data)
          alert('Tipo de transação atualizada!');
        } else {
          await api.post('transactiontypestores', data)
          alert('Tipo de transação incluída!');
        }

        history.push('/transactiontypes');
      } catch (err) {
        if(!!transactionType.id) {
          alert('Erro ao atualizar o tipo de transação!');
        } else {
          alert('Erro ao cadastrar o tipo de transação!');
        }
      }
    }, [history, transactionType]
  );

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Fato Gerador"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Tipo">
              <Form.Select
                value={transactionType.transactionType.id}
                onChange={handleSelectChange}
                name="type"
                disabled={!!id}
              >
                { defaultTypes.map((defaultTransactionType: TransactionType) => (
                    <option
                      key={defaultTransactionType.id}
                      value={defaultTransactionType.id}
                    >
                      { defaultTransactionType.name }
                    </option>
                )) }
              </Form.Select>
            </Form.Group>
            <Form.Group label={pointsLabel}>
              <Form.Input
                name="points"
                type="number"
                value={transactionType.points || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Limite de Uso">
              <Form.Input
                name="usage_limit"
                type="number"
                value={transactionType.usage_limit || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Checkbox
                label="Ativa"
                name="active"
                onChange={handleCheckboxChange}
                checked={!!transactionType.active ? true : false}
              />
            </Form.Group>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default TransactionTypesForm;