import React, { ChangeEvent, useEffect, useState } from "react";
import { Formik } from "formik";
import { Form, FormCard, FormCheckboxInput, FormTextInput, HTMLElementProps, StandaloneFormPage } from "tabler-react";
import { useHistory, useLocation } from 'react-router-dom';
import api from '../services/api';

import { useAuth } from "../hooks/AuthContext";

import logo from "../assets/img/logo.svg";
import Platform from "../interfaces/Platform";
import { StoreProgramTypeList } from '../interfaces/Store';
import NuvemShopAuthResponse from "../interfaces/NuvemShopAuthResponse";

import { AxiosResponse } from "axios";
import queryString from 'query-string';

type fieldTypes = {
  platform?: number;
  name?: string;
  email?: string;
  cnpj?: string;
  password?: string;
  terms?: string;
};

interface Props
  extends Omit<HTMLElementProps<HTMLFormElement>, "onChange" | "onBlur">,
    Pick<HTMLElementProps<HTMLInputElement>, "onChange" | "onBlur"> {
  errors?: fieldTypes;
}

function RegisterPage(props: Props) {
  const history = useHistory();

  const { signIn } = useAuth();

  const { search } = useLocation();
  const values = queryString.parse(search)
  const code = values.code;
  const coupon = values.coupon;
  const platformQuery = values.platform;

  const initialValues: Platform = {
    id: "",
    name: "",
    status: 1,
  };

  const [programType, setProgramType] = useState(1);
  const [platform, setPlatform] = useState<Platform>(initialValues);
  const [platformOptions, setPlatformOptions] = useState({});
  const [platforms, setPlatforms] = useState([]);
  const [couponCode, setCouponCode] = useState(coupon);

  const {
    onSubmit,
    onChange,
    onBlur,
    errors,
    action,
    method,
  } = props;

  useEffect(() => {
    api.get('platforms/all').then(response => {
      setPlatforms(response.data);
    });
    if(!!code) {
      api.post('nuvemshop/auth', { store_code: code }).then((response: AxiosResponse) => {
        const data = response.data as NuvemShopAuthResponse;
        setPlatformOptions({
          store_id: data.auth.user_id,
          access_token: data.auth.access_token,
        })
        const nsPlatform = {
          id: 3
        } as Platform;
        setPlatform(nsPlatform);
      })
    }
    if(!!platformQuery) {
      let platformId = 1;
      let platformQueryNormalized = platformQuery.toString().toLowerCase()
      switch(platformQueryNormalized) {
        case 'wbuy': {
          platformId = 8;
        }
      }
      setPlatform({
        id: platformId
      } as Platform);
    }
  }, [code]);


  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target;
    setProgramType(parseInt(value));
  }

  function handleChangeCoupon(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setCouponCode(value);
  }

  function handleSelectChangePlatform(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    const newPlatform = {
      id: parseInt(value)
    } as Platform;
    setPlatform(newPlatform);
  }

  return (
    <Formik
      initialValues={{
        email: "",
        name: "",
        password: "",
        cnpj: "",
        coupon: "",
        terms: "",
      }}
      validate={values => {
        // same as above, but feel free to move this into a class method now.
        let errors: any = {};
        if (!values.name) {
          errors.name = "Obrigatório";
        } else if(!values.cnpj) {
          errors.cnpj = "Obrigatório"
        } else if(!values.email) {
          errors.email = "Obrigatório"
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Endereço de e-mail inválido";
        } else if(!values.password) {
          errors.password = "Obrigatório"
        } else if(!values.terms) {
          errors.terms = "Obrigatório aceitar os termos"
        }
        return errors;
      }}
      onSubmit={async (
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        try {
          const data = {
            platform_id: platform.id,
            cnpj: values.cnpj,
            name: values.name,
            password: values.password,
            email: values.email,
            platform_options: platformOptions,
            program_type: programType,
            coupon_code: couponCode
          }
          await api.post('register', data)
          history.push('/finish-registration');
        } catch (err) {
          alert('Erro ao efetuar cadastro');
        }

      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <StandaloneFormPage imageURL={logo}>
          <FormCard
            buttonText="Criar conta"
            title="Criação de conta"
            onSubmit={handleSubmit}
            action={action}
            method={method}
          >
          <Form.Group label="Plataforma">
            <Form.Select
              value={platform.id}
              onChange={handleSelectChangePlatform}
              name="platform"
              disabled={!!code|| !!platformQuery}
            >
              { platforms.map((platform: Platform) => (
                  <option
                    key={platform.id}
                    value={platform.id}
                  >
                    { platform.name }
                  </option>
              )) }
            </Form.Select>
          </Form.Group>
          <Form.Group label="Tipo do Programa">
            <Form.Select
              value={programType}
              onChange={handleSelectChange}
              name="program_type"
            >
              { StoreProgramTypeList.map((type) => (
                <option
                  key={type.id}
                  value={type.id}
                >
                  { type.value }
                </option>
              )) }
            </Form.Select>
          </Form.Group>
          <FormTextInput
            name="name"
            label="Nome da Loja"
            placeholder="Nome"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values && values.name}
            error={errors && errors.name}
          />
          <FormTextInput
            name="cnpj"
            label="CNPJ"
            placeholder="CNPJ"
            onChange={handleChange}
            onBlur={onBlur}
            value={values && values.cnpj}
            error={errors && errors.cnpj}
          />
          <FormTextInput
            name="email"
            label="E-Mail"
            placeholder="E-Mail"
            onChange={handleChange}
            onBlur={onBlur}
            value={values && values.email}
            error={errors && errors.email}
          />
          <FormTextInput
            name="password"
            type="password"
            label="Senha"
            placeholder="Senha"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values && values.password}
            error={errors && errors.password}
          />
          <FormTextInput
            name="coupon"
            label="Cupom de Desconto"
            placeholder="Código"
            onChange={handleChangeCoupon}
            onBlur={onBlur}
            value={couponCode || ''}
            error={errors && errors.coupon}
            disabled={!!coupon}
          />
          <FormCheckboxInput
            onChange={handleChange}
            onBlur={handleBlur}
            value={values && values.terms}
            name="terms"
            label="Concordo com os termos e condições"
          />
          </FormCard>
        </StandaloneFormPage>
      )}
    />
  );
}

export default RegisterPage;