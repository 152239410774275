import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';
import ProductCategory from '../../interfaces/ProductCategory';

import {
  Page,
  Button,
  Card,
  Text,
  Table
} from "tabler-react";

function ProductCategoriesList() {

  const history = useHistory();

  const [productCategories, setProductCategories] = useState([]);

  useEffect(() => {
    updateProductCategoriesList();
  }, []);

  function updateProductCategoriesList() {
    api.get('productcategories').then(response => {
      setProductCategories(response.data);
    });
  }

  function handleEditClick(productCategory: ProductCategory) {
    history.push(`/productcategories/form/${productCategory.id}`);
  }

  function handleClickAdd() {
    history.push('/productcategories/form');
  }

  const handleDeleteClick = useCallback(
    async (productCategory: ProductCategory) => {
      try {
        if(window.confirm("Confirma a exclusão da configuração de categoria?")) {
          await api.delete(`productcategories/${productCategory.id}`);

          alert('Configuração de Categoria Excluída!');

          updateProductCategoriesList();
        }
      } catch (err) {
        alert('Erro ao excluir configuração!');
      }
  }, []);

  return (
    <SiteWrapper>
      <Page.Content title="Categorias">
        <Card>
          <Card.Header>
            <Card.Title>Configurações por Categorias</Card.Title>
            <Card.Options>
              <Button
                RootComponent="a"
                color="secondary"
                size="sm"
                icon="plus"
                onClick={handleClickAdd}
              >
                Incluir
              </Button>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Descrição"},
              { content: "Valor"},
              { content: null },
            ]}
            bodyItems={
              productCategories.map((productCategory: ProductCategory) => {
                return {
                  key: productCategory.id,
                  item: [
                    {
                      content: (
                        <Text>
                          {productCategory.description}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {productCategory.value}
                        </Text>
                      ),
                    },
                    {
                      alignContent: "right",
                      content: (
                        <>
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(productCategory)}
                          />
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="trash"
                            onClick={() => handleDeleteClick(productCategory)}
                          />
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
        </Card>
      </Page.Content>
    </SiteWrapper>
  );
}

export default ProductCategoriesList;