import { NavItemProps } from "components/Nav/NavItem";
import React from "react";
import { NavLink as ReactRouterNavLink } from "react-router-dom";

import { Site } from "tabler-react";

import { useAuth } from "../hooks/AuthContext";

const NavItems = () => {

  const { store } = useAuth();

  return [
    {
      value: "Dashboard",
      to: "/",
      icon: "home",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: 1
    },
    {
      value: "Clientes",
      to: "/customers",
      icon: "users",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: 1
    },
    {
      value: "Transações",
      to: (store.program_type === 1) ? "/transactions": "/cashbacktransactions",
      icon: "shuffle",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: 1
    },
    {
      value: "Fatos Geradores",
      to: "/transactiontypes",
      icon: "gift",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: 1
    },
    {
      value: "Resgates",
      to: (store.program_type === 1) ? "/recoveries": "/cashbackrecoveries",
      icon: "corner-left-down",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: 1
    },
    {
      value: "Tipos de Resgate",
      to: "/recoverytypes",
      icon: "compass",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: (store.program_type === 1) ? 1: 0
    },
    {
      value: "Produtos",
      to: "/products",
      icon: "shopping-bag",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: (store.program_type === 2) ? 1: 0
    },
    {
      value: "Categorias de Produtos",
      to: "/productcategories",
      icon: "list",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: (store.program_type === 2) ? 1: 0
    },
    {
      value: "Configurações",
      to: "/settings",
      icon: "settings",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: 1
    },
    {
      value: "Níveis",
      to: "/levels",
      icon: "target",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: (store.program_type === 1) ? 1 : 0
    },
    {
      value: "Notificações",
      to: "/notificationtypes",
      icon: "mail",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: 1
    },
    {
      value: "Gerador de Página",
      to: "/pagegenerator",
      icon: "clipboard",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: 1
    },
    {
      value: "Relatórios",
      to: "/reports",
      icon: "file",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: 1
    },
    {
      value: "Pagamento",
      to: "/subscription",
      icon: "credit-card",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: 0
    },
    {
      value: "Autenticação",
      to: "/configureauthentication",
      icon: "lock",
      linkAs: ReactRouterNavLink,
      linkProps: { exact: true },
      show: 1
    },
  ];
}

const SiteNav = () => {
  const navBarItems = NavItems();
  const visibleItems = navBarItems.filter((item) => (item.show === 1)) as NavItemProps[];

  return (
    <Site.Nav
      logoURL="/img/logo.svg"
      isSide={true}
      side="left"
      itemsObjects={visibleItems}
    />
  );
};

export default SiteNav;