import React from "react";

import SiteWrapper from "../../base/SiteWrapper";

import { useHistory } from 'react-router-dom';

import {
  Page,
  Card,
  Text,
  Button
} from "tabler-react";

const PageGeneratorForm = () => {
  const history = useHistory();

  return (
    <SiteWrapper>
      <Page.Content title="Gerador de Página">
        <Card>
          <Card.Body>
          <Text>Ao clicar no botão abaixo será gerada automaticamente uma página padrão com as regras configuradas para o seu plano de fidelidade.
            Você pode customizá-la e colocá-la em seu site para que o cliente visualize e entenda o plano de fidelidade.
          </Text>
          </Card.Body>
          <Card.Footer>
            <Button
              outline
              size="sm"
              RootComponent="a"
              color="secondary"
              onClick={() => {history.push('/pagegenerate')}}
            >
              Gerar
            </Button>
          </Card.Footer>
        </Card>
      </Page.Content>
    </SiteWrapper>
  )
}

export default PageGeneratorForm;