import React, { useState, ChangeEvent, useCallback, FormEvent, useEffect } from 'react';
import SiteWrapper from "../../base/SiteWrapper";
import { useHistory, useParams } from 'react-router-dom';
import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";

import Recovery from '../../interfaces/Recovery';
import { useAuth } from '../../hooks/AuthContext';
import api from '../../services/api';

interface RegenerateCouponResponse {
  id: string;
  codigo: string;
}

function RegenerateCouponForm() {

  const history = useHistory();
  const { store } = useAuth();

  const { id } = useParams();

  const [recovery, setRecovery] = useState<Recovery>({} as Recovery);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if(!!id) {
      if(store.program_type == 1) {
        api.get(`recoveries/${id}`).then(response => {
          setRecovery(response.data);
          setValue(response.data.recoveryTypeStore.options.value);
        });
      } else {
        api.get(`cashbackrecoveries/${id}`).then(response => {
          setRecovery(response.data);
          setValue(response.data.value);
        });
      }
    }
  }, [id]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        const data = {
          "recovery_id": recovery.id,
          "value": value,
        }

        const response = await api.post('regeneratecoupon', data)
        const responseData = response.data as RegenerateCouponResponse;
        alert(`Cupom gerado novamente! Novo código: ${responseData.codigo}`);

        const pushRoute = (store.program_type == 1) ? '/recoveries' : '/cashbackrecoveries'
        history.push(pushRoute);
      } catch (err) {
        alert('Erro ao gerar cupom novamente!');
      }
    }, [history, recovery, value]
  );

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setValue(parseFloat(value));
  }

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Gerar novo Cupom"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Gerar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Valor">
              <Form.Input
                name="value"
                value={value}
                onChange={handleChange}
              />
            </Form.Group>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default RegenerateCouponForm;