import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import SiteWrapper from "../../base/SiteWrapper";
import { useAuth } from '../../hooks/AuthContext';

import api from '../../services/api';

import Settings, { SettingsPositionList, SettingsTextColorList } from '../../interfaces/Settings';

import { useHistory } from 'react-router';

import {
  Page,
  Button,
  Form,
  Card,
  Grid
} from "tabler-react";

function SettingsForm() {

  const history = useHistory();

  const { store } = useAuth();

  let conversionFactorLabel = "Fator de Conversão (Quantos pontos equivalem a 1 real)";
  if(store.program_type === 2) {
    conversionFactorLabel = "Percentual de Cashback";
  }

  const initialValues: Settings = {
    id: '',
    expiration_days: '',
    conversion_factor: '',
    program_name: '',
    point_name: '',
    token: '',
    token_api: '',
    store_url: '',
    base_color: '#fff',
    title_color: '#fff',
    facebook_url: '',
    instagram_url: '',
    twitter_url: '',
    consider_freight_order: true,
    position_desktop: '',
    position_mobile: '',
    text_color: '',
    open_popup: false,
    consider_marketplace_orders: true,
    program_logo_name: '',
    program_logo_url: '',
    grace_days: '',
    minimum_cashback_recovery: '',
    reply_to_email: '',
    cashback_percent_vip: '',
    minimum_cashback_order: ''
  };

  const [selectedFile, setSelectedFile] = useState<File>();

  const [settings, setSettings] = useState<Settings>(initialValues);

  useEffect(() => {
    api.get('settings').then(response => {
      setSettings(response.data);
    });
  }, []);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setSettings({...settings, [name]: value});
  }

  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target;
    setSettings({...settings, [name]: parseInt(value)});
  }

  function handleChangeBaseColor(color: ColorResult) {
    setSettings({...settings, base_color: color.hex});
  }

  function handleChangeTitleColor(color: ColorResult) {
    setSettings({...settings, title_color: color.hex});
  }

  function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    setSettings({...settings, [name]: checked});
  }

  const handleChangeFile = useCallback(event => {
    if(!!event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  }, [selectedFile])

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        const data = new FormData();

        data.append('data', JSON.stringify(settings));

        await api.put(`settings/${settings.id}`, data);

        const dataFile = new FormData();
        if(selectedFile) {
          dataFile.append('program_logo', selectedFile);

          await api.post(`logoupload`, dataFile);
        }

        alert('Configuração atualizada!');
      } catch (err) {
        alert('Erro ao atualizar configurações!');
      }
    }, [settings, selectedFile]);

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleClickExcluirLogoPrograma = useCallback( async (event: FormEvent) => {
    try {
      event.preventDefault();
      await api.post('logoclear');
      alert('Logo excluída com sucesso!');
      window.location.reload();
    }
    catch (err) {
      alert('Erro ao excluir a logo.');
    }
  }, []);

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Configurações"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Nome do programa">
                  <Form.Input
                    name="program_name"
                    value={settings.program_name || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid.Col>
              { store.program_type === 1 &&
                <Grid.Col sm={12} lg={6}>
                  <Form.Group label="Nome dos pontos">
                    <Form.Input
                      name="point_name"
                      value={settings.point_name || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              }
              { store.program_type === 2 &&
                <Grid.Col sm={12} lg={6}>
                  <Form.Group label="Valor mínimo para Resgate">
                    <Form.Input
                      name="minimum_cashback_recovery"
                      value={settings.minimum_cashback_recovery || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              }
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Dias para expiração">
                  <Form.Input
                    name="expiration_days"
                    type="number"
                    value={settings.expiration_days || ""}
                    onChange={handleChange}
                  />
                  </Form.Group>
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label={conversionFactorLabel}>
                  <Form.Input
                    name="conversion_factor"
                    type="number"
                    value={settings.conversion_factor || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            { (store.platform_id === 1 && store.program_type === 2) &&
              <Grid.Row>
                <Grid.Col sm={12} lg={6}>
                  <Form.Group label="Percentual de Cashback para VIP">
                    <Form.Input
                      name="cashback_percent_vip"
                      type="number"
                      value={settings.cashback_percent_vip || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col sm={12} lg={6}>
                  <Form.Group label="Valor mínimo de pedido">
                    <Form.Input
                      name="minimum_cashback_order"
                      type="number"
                      value={settings.minimum_cashback_order || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            }
            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Dias de Carência">
                  <Form.Input
                    name="grace_days"
                    type="number"
                    value={settings.grace_days || ""}
                    onChange={handleChange}
                  />
                  </Form.Group>
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="E-Mail de Retorno Notificações">
                  <Form.Input
                    name="reply_to_email"
                    type="email"
                    value={settings.reply_to_email || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Considerar frete no valor total do pedido">
                  <Form.Switch
                    name="consider_freight_order"
                    onChange={handleCheckboxChange}
                    checked={!!settings.consider_freight_order ? true : false}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Url da Loja">
                  <Form.Input
                    name="store_url"
                    value={settings.store_url || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Url do Facebook">
                  <Form.Input
                    name="facebook_url"
                    value={settings.facebook_url || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Url do Instagram">
                  <Form.Input
                    name="instagram_url"
                    value={settings.instagram_url || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Url do Twitter">
                  <Form.Input
                    name="twitter_url"
                    value={settings.twitter_url || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm={12} lg={12}>
                <Form.Group label="Token de Integração">
                  <Form.Input
                    name="token"
                    value={settings.token || ""}
                    disabled
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm={12} lg={12}>
                <Form.Group label="Token API">
                  <Form.Input
                    name="token_api"
                    value={settings.token_api || ""}
                    disabled
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Posição Desktop">
                  <Form.Select
                    value={settings.position_desktop}
                    onChange={handleSelectChange}
                    name="position_desktop"
                  >
                    { SettingsPositionList.map((position) => (
                      <option
                        key={position.id}
                        value={position.id}
                      >
                        { position.value }
                      </option>
                    )) }
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Posição Mobile">
                  <Form.Select
                    value={settings.position_mobile}
                    onChange={handleSelectChange}
                    name="position_mobile"
                  >
                    { SettingsPositionList.map((position) => (
                      <option
                        key={position.id}
                        value={position.id}
                      >
                        { position.value }
                      </option>
                    )) }
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Abrir como Pop-up">
                  <Form.Switch
                    name="open_popup"
                    onChange={handleCheckboxChange}
                    checked={!!settings.open_popup ? true : false}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Cor do Texto">
                  <Form.Select
                    value={settings.text_color}
                    onChange={handleSelectChange}
                    name="text_color"
                  >
                    { SettingsTextColorList.map((textColor) => (
                      <option
                        key={textColor.id}
                        value={textColor.id}
                      >
                        { textColor.value }
                      </option>
                    )) }
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm={12} lg={3}>
                <Form.Group label="Cor base do Widget">
                  <SketchPicker
                    color={settings.base_color || '#fff'}
                    onChangeComplete={handleChangeBaseColor}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col sm={12} lg={3}>
                <Form.Group label="Cor do título do Widget">
                  <SketchPicker
                    color={settings.title_color || '#fff'}
                    onChangeComplete={handleChangeTitleColor}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                <Form.Group label="Logo do Programa (Tamanho recomendado: 40x40)">
                  <Form.FileInput
                    name='program_logo'
                    onChange={handleChangeFile}
                    accept="image/x-png,image/jpeg,image/jpg"
                    label={settings.program_logo_name}
                  />
                </Form.Group>
                {!!settings.program_logo_name
                  ? <Button onClick={handleClickExcluirLogoPrograma} color="primary" className="ml-auto">Limpar Logo do Programa</Button>
                  : <></>
                }
              </Grid.Col>
            </Grid.Row>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default SettingsForm;