import React from 'react';
import SiteWrapper from "../../base/SiteWrapper";

function RecoveriesForm() {

  return (
    <SiteWrapper>

    </SiteWrapper>
  );
}

export default RecoveriesForm;