import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SiteWrapper from "../../base/SiteWrapper";
import Product from '../../interfaces/Product';
import api from '../../services/api';

import {
  Page,
  Button,
  Form,
  Card,
  Icon
} from "tabler-react";

function ProductsForm() {

  const history = useHistory();

  const { id } = useParams();

  const initialValues: Product = {
    id: "",
    description: "",
    product_platform_id: "",
    value: 0,
  };

  const [product, setProduct] = useState<Product>(initialValues);

  useEffect(() => {
    if(!!id) {
      api.get(`products/${id}`).then(response => {
        setProduct(response.data);
      });
    }
  }, [id]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setProduct({...product, [name]: value});
  }

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        const data = {
          description: product.description,
          product_platform_id: product.product_platform_id,
          value: product.value,
        }

        if(!!product.id) {
          await api.put(`products/${product.id}`, data)
          alert('Configuração de produto atualizada!');
        } else {
          await api.post('products', data)
          alert('Configuração de produto incluída!');
        }

        history.push('/products');
      } catch (err) {
        if(!!product.id) {
          alert('Erro ao atualizar a configuração!');
        } else {
          alert('Erro ao cadastrar a configuração!');
        }
      }
    }, [history, product]
  );

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Produto"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Descrição">
              <Form.Input
                name="description"
                value={product.description || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                ID do produto na plataforma
                <Icon name="help-circle" style={{marginLeft:'5px'}} title="ID do produto na sua plataforma de e-commerce" />
              </Form.Label>
              <Form.Input
                name="product_platform_id"
                value={product.product_platform_id || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Valor
              </Form.Label>
              <Form.Input
                name="value"
                value={product.value || ""}
                onChange={handleChange}
                type="number"
              />
            </Form.Group>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default ProductsForm;