import React from "react";

import {
  Page,
  Card,
  Text
} from "tabler-react";

function FinishRegistration() {

  return (
    <Page.Content title="Finalizar Cadastro">
      <Card>
        <Card.Header>
          <Card.Title>Entre em contato conosco para finalizar seu cadastro</Card.Title>
        </Card.Header>
        <Card.Body>
          <Text>E-Mail: gustavo@fidegg.com.br</Text>
          <Text>Telefone: (47) 98856-1881</Text>
        </Card.Body>
      </Card>
    </Page.Content>
  );
}

export default FinishRegistration;