import React, { useEffect } from 'react';
import { Text, Card, Site } from "tabler-react";
import './Unsubscribe.css';
import { useLocation } from 'react-router-dom';
import api from '../../services/api';

function Unsubscribe() {

const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    if (token) {
      api.post('unsubscribe', { token })
        .then(response => {
          console.log('Unsubscribe successful:', response.data);
        })
        .catch(error => {
          console.error('Error during unsubscribe:', error);
        });
    } else {
      console.error('No token found in the URL');
    }
  }, [location.search]);

  return (
    <div className="unsubscribe-wrapper">
      <Site.Header
        href="/"
        alt="Fidegg"
        imageURL="/img/logo.svg"
        align="center"
      />
      <div className="content-unsubscribe">
        <img src="/img/icon_sad_face.svg" alt="Sad Face" className="sad-icon-unsubscribe" />
        <Card.Body className="card-body-unsubscribe">
          <Text className="text-content-unsubscribe">
            Agradecemos por ter feito parte da nossa comunidade! 
            Sentiremos sua falta, mas respeitamos sua decisão.
          </Text>
        </Card.Body>
      </div>
    </div>
  );
}

export default Unsubscribe;
