export const formatDate = (inputDate: string, inverted = true) => {
    const months: { [key: string]: string } = {
        Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
        Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
      };
    
      const parts = inputDate.split(' ');
      const year = parts[3];
      const month = months[parts[1]];
      const day = parts[2];
    if(inverted){
      return `${year}-${month}-${day}`;
    }
    return `${day}-${month}-${year}`;
  }

 export function formatDateToDDMMYYYY(dataString: string): string {
    const data = new Date(dataString);
    
    // Extrai os componentes da data
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = String(data.getFullYear());
  
    // Monta a string formatada
    const dataFormatada = `${dia}/${mes}/${ano}`;
    
    return dataFormatada;
  }