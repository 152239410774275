import React, { ChangeEvent, useEffect, useState } from "react";
import { Formik } from "formik";
import { Button, ForgotPasswordPage, Form, Text, StandaloneFormPage } from "tabler-react";
import logo from "../../assets/img/logo.svg";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import { validatePassword } from "../../utils/validatePassword";

type Props = {};

function ForgotPassword(props: Props) { 
  
    const history = useHistory();
    const [passwordError, setPasswordErr] = useState<string[]>([]);
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
    const [disableButton , setDisableButton] = useState(true);
    const [newPassword, setNewPassword] = useState<string>("")
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>(" ")
    const [backupEmail, setBackupEmail] = useState<string>()
    const [code, setCode] = useState<string>()

    useEffect(() => {
        if (passwordError.length === 0 && !!code && !!newPassword) {setDisableButton(false)};
    }, [passwordError, code, newPassword]);

        async function handleSubmitChangePassword(email?: string){
          try {
            const data = {
              email : backupEmail ?? email ,
              newPassword : newPassword,
              verificationCode : code
            }
            setBackupEmail(email!)
            if(resetPasswordSuccess) {
              await api.post("cognito/passwordchange", data)
              history.push('/');
            } else {
              await api.post("cognito/resetpassword", data)
              setResetPasswordSuccess(true)
            }
          } catch (err) {
            if(resetPasswordSuccess) {
              alert('Erro ao alterar sua senha!');
              setResetPasswordSuccess(false)
            } else {
              alert('Erro ao enviar código!');
            }
          }
        }
  
      const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newPasswordValue = event.currentTarget.value;
        setNewPassword(newPasswordValue);
        setPasswordErr(validatePassword(newPasswordValue, confirmNewPassword));
      };
    
      const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        const confirmNewPasswordValue = event.currentTarget.value;
        setConfirmNewPassword(confirmNewPasswordValue);
        setPasswordErr(validatePassword(newPassword, confirmNewPasswordValue));
      };

    const formattedPasswordErrors = passwordError.map((message, index) => (
        <Text key={index} color={"red"}>{message}</Text>
      ));

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validate={values => {
        // same as above, but feel free to move this into a class method now.
        let errors: any = {};
        if (!values.email) {
          errors.email = "Obrigatório";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Endereço de e-mail inválido";
        }
        return errors;
      }}
      onSubmit={async (
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        await handleSubmitChangePassword(values.email);
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          {resetPasswordSuccess ? 
            <StandaloneFormPage
              imageURL={logo}
              children={
                <Form>
                  <Form.Group label="Código" className="form-reset">
                    <Form.Input
                      placeholder="Código recebido por email"
                      name="code"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setCode(event.target.value)}
                      error={''}
                    />
                  </Form.Group> 
                  <Form.Group label="Nova Senha" className="form-reset">
                    <Form.Input
                      placeholder="Digite sua nova senha"
                      name="password"
                      onChange={handlePasswordChange}
                      error={passwordError.length > 0 ? " " : ""}
                      type="password"
                    />
                  </Form.Group>
                    <Form.Group label="Digite a nova senha novamente" className="form-reset">
                      <Form.Input
                        placeholder="Digite sua nova senha novamente"
                        onChange={handleConfirmPasswordChange}
                        name="confirmPassword"
                        error={passwordError.length > 0 ? " " : ""}
                        type="password"
                      />
                  </Form.Group>
                  {formattedPasswordErrors}
                  <Button
                    size="sm"
                    href="#"
                    RootComponent="a"
                    color="primary"  
                    onClick={handleSubmitChangePassword}
                    className="button-store"
                    disabled={disableButton}
                  >
                    Alterar Senha
                  </Button>
                </Form>
              }
            />
          : 
            <ForgotPasswordPage
              onSubmit={handleSubmit}
              onChange={handleChange}
              onBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
              logoUrl={logo}  
              strings={{
                title:"Esqueceu sua senha?",
                buttonText:"Enviar Código",
                emailLabel:"E-Mail",
                emailPlaceholder:"E-Mail",
                instructions: "Digite seu e-mail abaixo e enviaremos uma mensagem para redefinir sua senha "
              }}
            />
          }
        </>
      )}
    />
  );
}

export default ForgotPassword;
