import Customer from "./Customer";
import TransactionType from "./TransactionType";

const TransactionStatusList = [
  {id: 1, value: 'Pendente'},
  {id: 2, value: 'Aprovado'},
  {id: 3, value: 'Cancelado'},
];

export { TransactionStatusList };
export default interface Transaction {
  id: number | "";
  customer: Customer;
  transactionType: TransactionType
  value: number | "";
  points: number | "";
  date_time: Date | "";
  order_code: string;
  status: number;
}