import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Site, Nav, Button, Text, Form } from "tabler-react";

import { useAuth } from "../hooks/AuthContext";
import Store from "../interfaces/Store";

const SiteHeader = () => {
  const history = useHistory();

  const { signOut, store, storeList, signIn } = useAuth();

  const [stores, setStores] = useState<Store[]>();
  const [backUpStores, setbackUpStores] = useState<Store[]>([]);
  const [selected, setSelected] = useState<number>(store.id!);

  function handleSignOut() {
    try {
      signOut();
      history.push(store.isMonitor ? "/login-monitor" : "/");
    } catch (err) {
      alert("Erro ao efetuar logout");
    }
  }

  useEffect(() => {
    if (!!storeList) {
      setStores(storeList);
      setbackUpStores(storeList);
      setSelected(store.id!);
    }
  }, [storeList, store]);

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    let storesFilter = backUpStores?.filter((e) =>
      e.name
        .toLocaleLowerCase()
        .includes(event.target.value.toLocaleLowerCase())
    );
    setStores(storesFilter);
    setSelected(
      storesFilter.length > 0 && typeof storesFilter[0].id === "number"
        ? storesFilter[0].id
        : -1
    );
    if (event.target.value.length === 0) {
      setSelected(store.id!);
    }
  }

  function handleChangeSelected(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    return setSelected(parseInt(value));
  }

  async function handleSignInStore(value: number) {
    await signIn({
      id: value,
      isMonitor: true,
    });
    window.location.reload();
  }

  return (
    <Site.Header
      href="/"
      alt="Fidegg"
      imageURL="/img/logo.svg"
      navItems={[
        <Nav.Item link={false} className="mr-5" key="name">
          {stores ? (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSignInStore(selected);
              }}
            >
              <Form.InputGroup style={{ width: "30rem" }}>
                <Form.Select
                  style={{ height: "31px" }}
                  className="form-control-sm"
                  onChange={handleChangeSelected}
                  value={selected}
                >
                  {stores.map((store) => (
                    <option value={store.id} key={store.id}>
                      {store.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Input
                  className="form-control-sm"
                  placeholder="Nome"
                  name="name"
                  onChange={handleNameChange}
                />
                <span className="input-group-btn ml-2">
                  <Button
                    size="sm"
                    color="default"
                    type="submit"
                    icon="search"
                  />
                </span>
              </Form.InputGroup>
            </Form>
          ) : (
            <Text>{store.name}</Text>
          )}
        </Nav.Item>,
        <Nav.Item link={false} className="d-none d-md-flex" key="logout">
          <Button
            href="#"
            outline
            size="sm"
            RootComponent="a"
            color="primary"
            pill
            onClick={handleSignOut}
          >
            Sair
          </Button>
        </Nav.Item>,
      ]}
    />
  );
};

export default SiteHeader;
