import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';
import Level from '../../interfaces/Level';

import {
  Page,
  Button,
  Card,
  Text,
  Table
} from "tabler-react";

function LevelsList() {

  const history = useHistory();

  const [levels, setLevels] = useState([]);

  useEffect(() => {
    updateLevelsList();
  }, []);

  function updateLevelsList() {
    api.get('levels').then(response => {
      setLevels(response.data);
    });
  }

  function handleEditClick(level: Level) {
    history.push(`/levels/form/${level.id}`);
  }

  function handleClickAdd() {
    history.push('/levels/form');
  }

  const handleDeleteClick = useCallback(
    async (level: Level) => {
      try {
        if(window.confirm("Confirma a exclusão do nível?")) {
          await api.delete(`levels/${level.id}`);

          alert('Nível excluído!');

          updateLevelsList();
        }
      } catch (err) {
        alert('Erro ao excluir nível!');
      }
  }, []);

  return (
    <SiteWrapper>
      <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Níves</Card.Title>
            <Card.Options>
              <Button
                RootComponent="a"
                color="secondary"
                size="sm"
                icon="plus"
                disabled={levels.length >= 5}
                onClick={handleClickAdd}
              >
                Incluir
              </Button>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Nome"},
              { content: "Pontos Necessários"},
              { content: "Multiplicador"},
              { content: null },
            ]}
            bodyItems={
              levels.map((level: Level) => {
                return {
                  key: level.id,
                  item: [
                    {
                      content: (
                        <Text>
                          {level.name}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {level.points_needed}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {level.multiplier ? `${level.multiplier}%` : ''}
                        </Text>
                      ),
                    },
                    {
                      alignContent: "right",
                      content: (
                        <>
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(level)}
                          />
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="trash"
                            onClick={() => handleDeleteClick(level)}
                          />
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
        </Card>
      </Page.Content>
    </SiteWrapper>
  );
}

export default LevelsList;