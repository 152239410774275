
export const generateCSV = (headers: { label: string, key: string }[], data: any[], filename: string): void => {
    const arrayToCsv = (headers: any[], data: any[]): string => {
      const csvRows = [];
      const headerValues = headers.map(header => header.label);
      csvRows.push(headerValues.join(','));
  
      for (const row of data) {
        const rowValues = headers.map(header => {
          const escaped = ('' + row[header.key]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(rowValues.join(','));
      }
  
      return csvRows.join('\n');
    };
  
    const download = (csvData: string, fileName: string): void => {
      const blob = new Blob([csvData], { type: 'xlsx' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', fileName + '.xlsx');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
  
    if (headers.length > 0 && data.length > 0) {
      const csvData = arrayToCsv(headers, data);
      download(csvData, filename);
    }else{
      alert("Não há registros para geração do arquivo");
    }
  };