import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';
import RecoveryTypeStore from '../../interfaces/RecoveryTypeStore';

import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";
import RecoveryType from '../../interfaces/RecoveryType';

function RecoveryTypesForm() {

  const history = useHistory();

  const { id } = useParams();

  const initialValues: RecoveryTypeStore = {
    id: "",
    description: "",
    points_needed: "",
    active: "",
    usage_limit: "",
    options: {},
    recoveryType: {
      id: "",
      name: ""
    }
  };

  const [recoveryType, setRecoveryType] = useState<RecoveryTypeStore>(initialValues);
  const [defaultTypes, setDefaultTypes] = useState([]);

  useEffect(() => {
    api.get('recoverytypes').then(response => {
      setDefaultTypes(response.data);
      setRecoveryType({...recoveryType, recoveryType: response.data[0]});
    });
    if(!!id) {
      api.get(`recoverytypestores/${id}`).then(response => {
        setRecoveryType(response.data);
      });
    }
  }, [id]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setRecoveryType({...recoveryType, [name]: value});
  }

  function handleChangeOptions(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const options = {...recoveryType.options, [name]: value}
    setRecoveryType({...recoveryType, options});
  }

  function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    setRecoveryType({...recoveryType, [name]: checked});
  }

  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    const newRecoveryType = {
      id: parseInt(value)
    } as RecoveryType;
    setRecoveryType({...recoveryType, recoveryType: newRecoveryType});
  }

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        const data = {
          "points_needed": recoveryType.points_needed,
          "description": recoveryType.description,
          "usage_limit": recoveryType.usage_limit,
          "active": recoveryType.active,
          "recovery_type_id": recoveryType.recoveryType.id,
          "options": recoveryType.options
        }

        if(!!recoveryType.id) {
          await api.put(`recoverytypestores/${recoveryType.id}`, data)
          alert('Tipo de recuperação atualizado!');
        } else {
          await api.post('recoverytypestores', data)
          alert('Tipo de recuperação incluído!');
        }

        history.push('/recoverytypes');
      } catch (err) {
        if(!!recoveryType.id) {
          alert('Erro ao atualizar o tipo de recuperação!');
        } else {
          alert('Erro ao cadastrar o tipo de recuperação!');
        }
      }
    }, [history, recoveryType]
  );

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Tipo de Recuperação"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Tipo">
              <Form.Select
                value={recoveryType.recoveryType.id}
                onChange={handleSelectChange}
                name="type"
                disabled={!!id}
              >
                { defaultTypes.map((defaultRecoveryType: RecoveryType) => {
                  return (
                    <option
                      key={defaultRecoveryType.id}
                      value={defaultRecoveryType.id}
                    >
                      { defaultRecoveryType.name }
                    </option>
                )}) }
              </Form.Select>
            </Form.Group>
            <Form.Group label="Descrição">
              <Form.Input
                name="description"
                value={recoveryType.description || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Pontos Necessários">
              <Form.Input
                name="points_needed"
                value={recoveryType.points_needed || ""}
                onChange={handleChange}
                type="number"
              />
            </Form.Group>
            <Form.Group label="Limite de Uso">
              <Form.Input
                name="usage_limit"
                value={recoveryType.usage_limit || ""}
                onChange={handleChange}
                type="number"
              />
            </Form.Group>
            {(recoveryType.recoveryType.id === 1 && !!recoveryType.options) &&
              <Fragment>
                <Form.Group label="Valor">
                  <Form.Input
                    name="value"
                    value={recoveryType.options.value || ""}
                    onChange={handleChangeOptions}
                  />
                </Form.Group>
                <Form.Group label="Valor Mínimo">
                  <Form.Input
                    name="minimum_value"
                    value={recoveryType.options.minimum_value || ""}
                    onChange={handleChangeOptions}
                  />
                </Form.Group>
              </Fragment>
            }
            {(recoveryType.recoveryType.id === 2 && !!recoveryType.options) &&
              <Form.Group label="Percentual">
                <Form.Input
                  name="value"
                  value={recoveryType.options.value || ""}
                  onChange={handleChangeOptions}
                />
              </Form.Group>
            }
            <Form.Group>
              <Form.Checkbox
                label="Ativo"
                name="active"
                onChange={handleCheckboxChange}
                checked={!!recoveryType.active ? true : false}
              />
            </Form.Group>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default RecoveryTypesForm;