import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';
import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import Customer from '../../interfaces/Customer';

import Moment from 'moment';

import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";

function CustomersForm() {

  const history = useHistory();

  const { store } = useAuth();

  const { id } = useParams();

  const initialValues: Customer = {
    id: "",
    name: "",
    surname: "",
    birthday: "",
    customer_document: "",
    email: "",
    entry_date: "",
    phone_number: "",
    points_balance: 0,
    cashback_vip: false
  };

  const [customer, setCustomer] = useState<Customer>(initialValues);

  useEffect(() => {
    if(!!id) {
      api.get(`customers/${id}`).then(response => {
        setCustomer(response.data);
      });
    }
  }, [id]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setCustomer({...customer, [name]: value});
  }

  function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    setCustomer({...customer, [name]: checked});
  }

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        const data = {
          "name": customer.name,
          "surname": customer.surname,
          "customer_document": customer.customer_document,
          "email": customer.email,
          "birthday": customer.birthday,
          "entry_date": customer.entry_date,
          "phone_number": customer.phone_number,
          "points_balance": customer.points_balance,
          "cashback_vip": customer.cashback_vip
        }

        if(!!customer.id) {
          await api.put(`customers/${customer.id}`, data)
          alert('Cliente atualizado!');
        } else {
          await api.post('customers', data)
          alert('Cliente incluído!');
        }

        history.push('/customers');
      } catch (err) {
        if(!!customer.id) {
          alert('Erro ao atualizar o cliente!');
        } else {
          alert('Erro ao cadastrar o cliente!');
        }
      }
    }, [history, customer]
  );

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Cliente"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="CPF/CNPJ">
              <Form.Input
                name="customer_document"
                value={customer.customer_document || ""}
                onChange={handleChange}
                readOnly={!!id}
              />
            </Form.Group>
            <Form.Group label="Nome">
              <Form.Input
                name="name"
                value={customer.name || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Sobrenome">
              <Form.Input
                name="surname"
                value={customer.surname || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="E-Mail">
              <Form.Input
                name="email"
                value={customer.email}
                onChange={handleChange}
                readOnly={!!id}
              />
            </Form.Group>
            <Form.Group label="Telefone">
              <Form.Input
                name="phone_number"
                value={customer.phone_number}
                onChange={handleChange}
                readOnly={!!id}
              />
            </Form.Group>
            <Form.Group label="Data de Aniversário">
              <Form.Input
                name="birthday"
                value={!!customer.birthday ? Moment(customer.birthday).format('DD/MM/YYYY'): ''}
                onChange={handleChange}
                readOnly={!!id}
              />
            </Form.Group>
            <Form.Group label="Saldo">
              <Form.Input
                name="points_balance"
                value={customer.points_balance}
                onChange={handleChange}
                type="number"
              />
            </Form.Group>
            { store.program_type === 2 &&
              <Form.Group label="VIP">
                <Form.Switch
                  name="cashback_vip"
                  checked={!!customer.cashback_vip ? true : false}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
            }
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default CustomersForm;