import React, { ChangeEvent, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import SiteWrapper from "../../base/SiteWrapper";

import { Page, Card, Text, Grid, Button, Form } from "tabler-react";
import api from "../../services/api";
import { useAuth } from "../../hooks/AuthContext";

const ConfigureAuthentication = () => {

  const { store } = useAuth();

  const textButton = (store.twoFactorAuthentication) ? "Desativar 2FA" : "Ativar 2FA";
  const textInfosPage = (store.twoFactorAuthentication) ? "Confirme sua senha, código de autenticação e clique no botão abaixo para desativar a autenticação de dois fatores para sua conta."
  : "Ao confirmar sua senha e clicar no botão abaixo será gerada um QRCode. Você pode usar um aplicativo de autenticação móvel, para escanear o código e configurar facilmente a autenticação de dois fatores em sua conta."

  const [enableQRCode, setEnableQRCode] = useState(false);
  const [qrcodeLink, setQRCodeLink] = useState("");
  const [code, setCode] = useState<string>()
  const [password, setPassword] = useState<string>("")
  const [disableButton, setDisableButton] = useState(true)

  useEffect(() => {
    if(!store.twoFactorAuthentication && password.length > 7) { return setDisableButton(false) }
    if(store.twoFactorAuthentication && password.length > 7 && code) { setDisableButton(false) } 
    else { setDisableButton(true) }
  }, [store, password, code]);

  const handleKeyDisable2FA = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !disableButton) {
      enablerOrDisable2FA();
    }
  };

  const handleKeyConfirmCodeTotp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !!code) {
      confirmCodeTotp();
    }
  };

  async function enablerOrDisable2FA() {
    const data = {
      email: store.email,
      password: password,
      codeTotp: code,
    };
    try {
      if(!store.twoFactorAuthentication){
        const qrCode = await api.post("cognito/generateqrcode", data);
        setQRCodeLink(qrCode.data.URL);
        setEnableQRCode(true);
      }
      if(store.twoFactorAuthentication){
        await api.post("cognito/disable2fa", data);
        store.twoFactorAuthentication = false
        localStorage.setItem('@fidegg:store', JSON.stringify(store));
        window.location.reload();
      }
    } catch (err) {
      alert("Autenticação do usuário falhou");
    }
  }

  async function confirmCodeTotp() {
    const data = {
      email: store.email,
      password: password,
      codeTotp: code,
    };
    try {
      await api.post("cognito/confirmecodetotp", data);
      store.twoFactorAuthentication = true
      localStorage.setItem('@fidegg:store', JSON.stringify(store));
      window.location.reload();
    } catch (err) {
      alert("Não foi possível ativar a autenticação de dois fatores !");
    }
  }

  return (
    <SiteWrapper>
      <Page.Content title="Autenticação">
        <Card>
          <Card.Body>
            <Text>
              {textInfosPage}
            </Text>
          </Card.Body>
          <Card.Footer>
            <Card.Options>
              <Form onSubmit={(e) => {
                e.preventDefault();
                if(!disableButton){enablerOrDisable2FA();}
              }}>
                <Form.InputGroup d={"flex"} >
                  <Form.Input
                    mr={3}
                    className="form-control-sm"
                    placeholder="Senha"
                    name="password"
                    type="password"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                  />
                  {!store.twoFactorAuthentication ? null :
                  <Form.Input
                    mr={3}
                    className="form-control-sm"
                    placeholder="2FA"
                    name="code"
                    onKeyDown={handleKeyDisable2FA}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setCode(event.target.value)}
                  />
                  }
                    <Button
                      outline
                      size="sm"
                      RootComponent="a"
                      color="secondary"
                      onClick={enablerOrDisable2FA}
                      disabled={disableButton}
                    >
                    {textButton}
                  </Button>
                </Form.InputGroup>
              </Form>
            </Card.Options>
          </Card.Footer>
        </Card>
        {!enableQRCode ? null : (
          <Grid.Row d={"flex"} style={{justifyContent:"center"}}>
           <Grid.Col md={6} xl={4} >
           <Card>
             <Card.Header>
               <Card.Title>{"QRCode"}</Card.Title>
             </Card.Header>
             <Card.Body ml={6}>
              <QRCode size={256} value={qrcodeLink} viewBox={`0 0 256 256`}/>
              <Form.Group 
                label="Digite o código "
                className="form-reset"
                mt={6}
               >
                <Form.Input
                  placeholder="Code 2FA"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setCode(event.target.value)}
                  style={{width:"86%"}}
                  name="confirmPassword" 
                  onKeyDown={handleKeyConfirmCodeTotp}     
                  height={2}
                  />
                  </Form.Group>
                <Button
                  size="sm"
                  href="#"
                  RootComponent="a"
                  color="primary"  
                  style={{width:"86%"}}
                  onClick={confirmCodeTotp}
                  disabled={!code}
                  >
                  Verificar Codígo
                </Button>
             </Card.Body>
           </Card>
         </Grid.Col>
        </Grid.Row> 
        )}
      </Page.Content>
    </SiteWrapper>
  );
};

export default ConfigureAuthentication;
