export const validatePassword = (passwordInputValue: string, passwordConfirmation?: string) => {
    let isSamePasswords;
    const hasUppercase = /[A-Z]/.test(passwordInputValue || passwordConfirmation!);
    const hasLowercase = /[a-z]/.test(passwordInputValue || passwordConfirmation!);
    const hasDigit = /[0-9]/.test(passwordInputValue || passwordConfirmation!);
    const hasSpecialChar = /[#?!@$%^&*-]/.test(passwordInputValue || passwordConfirmation!);
    const isMinLength = passwordInputValue.length >= 8 || passwordConfirmation!.length >= 8;
    if(!!passwordConfirmation){
        isSamePasswords = passwordConfirmation === passwordInputValue
    }
    const errors = [];
  
    if (!hasUppercase) {
      errors.push("Pelo menos uma maiúscula");
    }
    if (!hasLowercase) {
      errors.push("Pelo menos uma minúscula");
    }
    if (!hasDigit) {
      errors.push("Pelo menos um dígito");
    }
    if (!hasSpecialChar) {
      errors.push("Pelo menos um caractere especial");
    }
    if (!isMinLength) {
      errors.push("Pelo menos 8 caracteres");
    }
    if (!isSamePasswords && !!passwordConfirmation) {
        errors.push("As senhas devem ser iguais");
    }
    return errors;
  };
  