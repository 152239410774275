import Customer from "./Customer";
import TransactionType from "./TransactionType";

const TransactionStatusList = [
  {id: 1, value: 'Pendente'},
  {id: 2, value: 'Aprovado'},
  {id: 3, value: 'Cancelado'},
];

export { TransactionStatusList };
export default interface CashbackTransaction {
  id: number | "";
  customer: Customer;
  birthday: string | "";
  transactionType: TransactionType
  value: number | "";
  cashback_value: number | "";
  date_time: Date | "";
  order_code: string;
  status: number;
}

export default interface AvailableCashbackTransaction {
  name: string | "";
  surname: string | "";
  customer_document: string | "";
  cashback_value: number | "";
  points: number | "";
  points_balance: number | "";
  email: string | "";
  phone_number: string | "";
  birthday:string | "";
  expiration_date: Date | "";
  approval_date: Date | "";
  transaction_type: string | "";
}