import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';

import Moment from 'react-moment';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';
import Customer from '../../interfaces/Customer';

import {
  Page,
  Card,
  Table,
  Text,
  Button,
  Form,
  Dropdown,
} from "tabler-react";
import ReactPaginate from 'react-paginate';
import { useAuth } from '../../hooks/AuthContext';
import { useHistory } from 'react-router';

function CustomersList() {
  const history = useHistory();

  const [customers, setCustomers] = useState([]);
  const [documentSearch, setDocumentSearch] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [surnameSearch, setSurnameSearch] = useState('');
  const [page, setPage]= useState(1);
  const [pageCount, setPageCount]= useState(0);

  const { store } = useAuth();
  const balanceLabel = (store.program_type === 1) ? 'Pontos Válidos' : 'Saldo Válido'

  useEffect(() => {
    updateCustomersList();
  }, [page]);

  async function updateCustomersList() {
    const params = {
      page,
      name: nameSearch,
      surname: surnameSearch,
      document: documentSearch
    };
    await api.get('customers', { params }).then(response => {
      setCustomers(response.data.customers);
      setPageCount(response.data.pages);
    });
  }

  async function handleSubmitSearch(event: FormEvent) {
    event.preventDefault();

    await updateCustomersList();
  }

  function handleDocumentChange(event: ChangeEvent<HTMLInputElement>) {
    setDocumentSearch(event.target.value);
  }

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setNameSearch(event.target.value);
  }

  function handleSurnameChange(event: ChangeEvent<HTMLInputElement>) {
    setSurnameSearch(event.target.value);
  }

  const handlePaginate = useCallback((selectedItem: { selected: number }) => {
    setPage(selectedItem.selected + 1);
  }, [page]);

  function handleClickAdd() {
    history.push('/customers/form');
  }

  function handleClickDownload() {
    api.get('/customersdownload', { responseType: 'blob' }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'clientes.csv');
      document.body.appendChild(link);
      link.click();
    });
  }

  function handleEditClick(customer: Customer) {
    history.push(`/customers/form/${customer.id}`);
  }

  function handlePhoneClick(customer: Customer) {
    window.open(`https://api.whatsapp.com/send?1=pt_BR&phone=${customer.phone_number}"`)
  }

  function handleClickImportVips() {
    history.push('/customers/importVips');
  }

  return (
    <SiteWrapper>
      <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Clientes</Card.Title>
            <Card.Options>
              <Form onSubmit={handleSubmitSearch}>
                <Form.InputGroup>
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Nome"
                    name="name"
                    onChange={handleNameChange}
                  />
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Sobrenome"
                    name="surname"
                    onChange={handleSurnameChange}
                  />
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Documento"
                    name="document"
                    onChange={handleDocumentChange}
                  />
                  <span className="input-group-btn ml-2">
                    <Button
                      size="sm"
                      color="default"
                      type="submit"
                      icon="search"
                    />
                  </span>
                </Form.InputGroup>
              </Form>
              <Button
                RootComponent="a"
                color="secondary"
                size="sm"
                icon="plus"
                onClick={handleClickAdd}
              >
                Incluir
              </Button>
              <Button.Dropdown>
                <Dropdown.Item
                  RootComponent="a"
                  color="secondary"
                  onClick={handleClickDownload}
                >
                  Download CSV
                </Dropdown.Item>
              {
                (store.platform_id === 1 && store.program_type === 2) &&
                  <Dropdown.Item
                    color="secondary"
                    RootComponent="a"
                    onClick={handleClickImportVips}
                  >
                    Importar Vips
                  </Dropdown.Item>

              }
              </Button.Dropdown>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Nome", className: "w-1" },
              { content: "E-Mail" },
              { content: "Documento" },
              { content: "Data de entrada" },
              { content: "Telefone" },
              { content: balanceLabel },
              { content: null },
            ]}
            bodyItems={
              customers.map((customer: Customer) => {
                let balance = customer.points_balance ? customer.points_balance.toString() : 0;
                if(store.program_type === 2) {
                  balance = `R$ ${Number(balance).toFixed(2).replace('.', ',')}`;
                }

                return {
                  key: customer.id,
                  item: [
                    {
                      content: (
                        <Text>
                          { customer.name } { customer.surname }
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          { customer.email }
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          { customer.customer_document }
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Moment format="DD/MM/YYYY HH:mm:ss">
                          { customer.entry_date }
                        </Moment>
                      )
                    },
                    {
                      content: (
                        <Text>
                          { customer.phone_number }
                        </Text>
                      )
                    },
                    {
                      content: (
                        <Text>
                          { balance }
                        </Text>
                      )
                    },
                    {
                      alignContent: "left",
                      content: (
                        <>
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(customer)}
                          />
                          {
                            (!!customer.phone_number) &&
                            <Button
                              RootComponent="a"
                              color="secondary"
                              size="sm"
                              className="m-1"
                              icon="phone"
                              onClick={() => handlePhoneClick(customer)}
                            />
                          }
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
          <Card.Footer>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePaginate}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </Card.Footer>
        </Card>
      </Page.Content>
    </SiteWrapper>
  );
}

export default CustomersList;