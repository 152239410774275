import React, { useEffect, useState } from 'react';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import {
  Page,
  Card,
  Table,
  Text,
  Button
} from "tabler-react";

import { useHistory } from 'react-router-dom';
import NotificationTypeStore from '../../interfaces/NotificationTypeStore';

function RecoveriesList() {

  const history = useHistory();

  const [recoveryTypeStores, setRecoveryTypeStores] = useState([]);

  useEffect(() => {
    api.get('notificationtypestores').then(response => {
      setRecoveryTypeStores(response.data);
    });
  }, []);

  function handleClickAdd() {
    history.push('/notificationtypes/form');
  }

  function handleEditClick(notificationTypeStore: NotificationTypeStore) {
    history.push(`/notificationtypes/form/${notificationTypeStore.id}`);
  }

  return (
    <SiteWrapper>
      <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Tipos de Notificação</Card.Title>
            <Card.Options>
            <Button
                RootComponent="a"
                color="secondary"
                size="sm"
                icon="plus"
                onClick={handleClickAdd}
              >
                Incluir
              </Button>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Tipo" },
              { content: "Título" },
              { content: null },
            ]}
            bodyItems={
              recoveryTypeStores.map((notificationTypeStore: NotificationTypeStore) => {
                return {
                  key: notificationTypeStore.id,
                  item: [
                    {
                      content: (
                        <Text>
                          { notificationTypeStore.notificationType.name }
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          { notificationTypeStore.subject }
                        </Text>
                      ),
                    },
                    {
                      alignContent: "right",
                      content: (
                        <>
                           <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(notificationTypeStore)}
                          />
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
        </Card>
      </Page.Content>
    </SiteWrapper>
  );
}

export default RecoveriesList;