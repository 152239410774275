import React, { useEffect, useState } from "react";

import SiteWrapper from "../base/SiteWrapper";

import C3Chart from "../C3Chart";

import api from '../services/api';

import { useAuth } from "../hooks/AuthContext";

import {
  Page,
  Card,
  Grid,
  colors
} from "tabler-react";

import { ChartConfiguration } from "c3";

type cardsConfig = ChartConfiguration;

interface Points {
  week: number;
  month: number;
  year: number;
  total: number;
}

interface RepurchaseRate {
  months: string[]
  percentages: number[]
}

interface UsersByLevel {
  quantities: number[]
  levels: string[]
}

interface Kpis {
  avgorder: number;
  average: number;
  repeat_purchase: number;
  customers: number;
}

const Home = () => {

  const { store } = useAuth();
  const conqueredTitle = (store.program_type === 1) ? 'Pontos Conquistados' : 'Cashback Conquistado'
  const recoveredTitle = (store.program_type === 1) ? 'Pontos Recuperados' : 'Cashback Recuperado'

  const [pointsConquered, setPointsConquered] = useState<Points>({} as Points);
  const [pointsRecovered, setPointsRecovered] = useState<Points>({} as Points);
  const [repurchaseRate, setRepurchaseRate] = useState<RepurchaseRate>({months: [], percentages: []} as RepurchaseRate);
  const [usersByLevel, setUsersByLevel] = useState<UsersByLevel>({quantities: [], levels: []} as UsersByLevel);
  const [kpis, setKpis] = useState<Kpis>({} as Kpis);

  useEffect(() => {
    api.get('pointsconquered').then(response => {
      setPointsConquered(response.data);
    });

    api.get('pointsrecovered').then(response => {
      setPointsRecovered(response.data);
    });

    api.get('repurchaserate').then(response => {
      setRepurchaseRate(response.data);
    });

    api.get('usersbylevel').then(response => {
      setUsersByLevel(response.data);
    });

    api.get('kpis').then(response => {
      setKpis(response.data);
    });
  }, []);

  const cards: cardsConfig[] = [
    {
      title: {
        text: "Taxa de Recompra"
      },
      data: {
        columns: [
          // each columns data
          ["tax", ...repurchaseRate.percentages]
        ],
        type: "line", // default type of chart
        colors: {
          tax: colors.blue
        },
        names: {
          // name of each serie
          tax: "Taxa"
        },
      },
      axis: {
        x: {
          type: "category",
          // name of each category
          categories: repurchaseRate.months,
        },
      },
    },
  ];
  if(store.program_type === 1) {
    cards.push(
    {
      title: {
        text: "Usuários por Nível",
      },
      data: {
        columns: [
          // each columns data
          ["quantity", ...usersByLevel.quantities],
        ],
        type: "bar", // default type of chart
        colors: {
          quantity: colors["blue"],
        },
        names: {
          // name of each serie
          quantity: "Usuários",
        },
      },
      axis: {
        x: {
          type: "category",
          // name of each category
          categories: usersByLevel.levels,
        },
      },
    });
  }

  return (
    <SiteWrapper>
      <Page.Content title="Dashboard">
        <Grid.Row>
          <Grid.Col sm={12} lg={6}>
            <Card>
              <Card.Header>
                <Card.Title>{conqueredTitle}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Grid.Row>
                  <Grid.Col sm={12} lg={6}>
                    <Card
                      title="Semanal"
                      body={pointsConquered.week}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={6}>
                    <Card
                      title="Mensal"
                      body={pointsConquered.month}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={6}>
                    <Card
                      title="Anual"
                      body={pointsConquered.year}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={6}>
                    <Card
                      title="Total"
                      body={pointsConquered.total}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Card>
          </Grid.Col>
          <Grid.Col sm={12} lg={6}>
            <Card>
              <Card.Header>
                <Card.Title>{recoveredTitle}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Grid.Row>
                  <Grid.Col sm={12} lg={6}>
                    <Card
                      title="Semanal"
                      body={pointsRecovered.week}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={6}>
                    <Card
                      title="Mensal"
                      body={pointsRecovered.month}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={6}>
                    <Card
                      title="Anual"
                      body={pointsRecovered.year}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={6}>
                    <Card
                      title="Total"
                      body={pointsRecovered.total}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Card>
          </Grid.Col>
          <Grid.Col sm={12} lg={6}>
            <Card>
              <Card.Header>
                <Card.Title>KPI's (Anual)</Card.Title>
              </Card.Header>
              <Card.Body>
                <Grid.Row>
                  <Grid.Col sm={12} lg={4}>
                    <Card
                      title="Ticket Médio"
                      body={kpis.avgorder}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={4}>
                    <Card
                      title="Média de Pedidos"
                      body={kpis.average}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={4}>
                    <Card
                      title="Frequência de Recompra"
                      body={`${kpis.repeat_purchase} %`}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col sm={12} lg={4}>
                  <Card
                      title="Quantidade de Clientes"
                      body={kpis.customers}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Card>
          </Grid.Col>
          {cards.map((chart, i) => (
            <Grid.Col key={i} md={6} xl={6}>
              <Card>
                <Card.Header>
                  <Card.Title>{chart.title?.text}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <C3Chart
                    data={chart.data}
                    axis={chart.axis}
                    legend={{
                      show: false, //hide legend
                    }}
                    padding={{
                      bottom: 0,
                      top: 0,
                    }}
                  />
                </Card.Body>
              </Card>
            </Grid.Col>
          ))}
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default Home;