export const maskCurrency = (valor: string) => {
    const formattedValue = parseFloat(valor.replace(",", ".")).toFixed(2);
    return formattedValue;
  };
  
  export const applyCurrencyMask = (value: string) => {
    const onlyDigits = value
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(2, "0");
     
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
     
    return maskCurrency(digitsFloat);
  };